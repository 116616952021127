<!--<template>
    <svg width="1440" height="743" fill="none" xmlns="http://www.w3.org/2000/svg">

        <symbol id="blog-1" class="home-page__svg" viewBox="1440 0 1440 743" >
            <path d="M1440 5H1557.5" stroke="#00A4FF" stroke-width="2"/>
            <circle cx="1553.5" cy="5" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
            <circle cx="1702.5" cy="5" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
            <path d="M1699 5L1777.5 5L1795.5 28V267.534H1688.5V438" stroke="#00A4FF" stroke-width="2"/>
            <path d="M1795 460H1905" stroke="#00A4FF" stroke-width="2"/>
            <circle cx="1908.5" cy="460" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
            <circle cx="2703.5" cy="460" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
            <path d="M2705 460H2880.5" stroke="#00A4FF" stroke-width="2"/>
        </symbol>

        <symbol id="blog-2" viewBox="0 0 474 465">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M288 206.019L288 223L304.209 206.019L405.962 206.019L405.962 133L288 133L288 206.019L288 206.019Z" fill="white"/>
        </symbol>

        <symbol id="comb-2" viewBox="0 0 1440 743">
            <use xlink:href="#blog-1" x="0" y="0" width="100%" height="100%"/>
            <use xlink:href="#blog-2" x="0" y="0" width="100%" height="100%"/>
        </symbol>

        <use xlink:href="#comb-2" x="0" y="0" width="100%" height="100%"/>
    </svg>
</template> -->


<template>
    <svg class="home-page__svg" width="1440" height="743" viewBox="1440 0 1440 743" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1440 5H1557.5" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="1553.5" cy="5" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
        <circle cx="1702.5" cy="5" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
        <path d="M1699 5L1777.5 5L1795.5 28V267.534H1688.5V438" stroke="#00A4FF" stroke-width="2"/>
        <path d="M1795 460H1905" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="1908.5" cy="460" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
        <circle cx="2703.5" cy="460" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
        <path d="M2705 460H2880.5" stroke="#00A4FF" stroke-width="2"/>

    </svg>
</template>
