<template>
  <svg style="display: none;">
    <symbol id="plus">
      <rect x="4.30786" y="0.921875" width="1.50085" height="9.99374" fill="#C4C4C4"/>
      <rect x="0.0197754" y="6.66211" width="1.4884" height="10.077" transform="rotate(-90 0.0197754 6.66211)"
            fill="#C4C4C4"/>

    </symbol>
  </svg>
</template>
