<template>
  <svg class="home-page__svg" width="1366" height="450" viewBox="0 0 1366 450" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M0 111 h20 V25 l20 -20 h150" stroke="#00A4FF" stroke-width="2"/>
    <circle cx="190" cy="5" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
    <circle cx="85" cy="5" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
    <path d="M85 5 v40 h85 l16 16 v321 l16 16 h150" stroke="#00A4FF" stroke-width="2"/>
    <circle cx="355" cy="398" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

    <path d="M185 126 h170" stroke="#00A4FF" stroke-width="2"/>
    <circle cx="355" cy="126" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

    <path d="M185 165 h170" stroke="#00A4FF" stroke-width="2"/>
    <circle cx="355" cy="165" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

    <path d="M185 242 h170" stroke="#00A4FF" stroke-width="2"/>
    <circle cx="355" cy="242" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

    <path d="M185 281 h170" stroke="#00A4FF" stroke-width="2"/>
    <circle cx="355" cy="281" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

    <path d="M185 320 h170" stroke="#00A4FF" stroke-width="2"/>
    <circle cx="355" cy="320" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

    <path d="M185 359 h170" stroke="#00A4FF" stroke-width="2"/>
    <circle cx="355" cy="359" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

    <path d="M1085 410 h300" stroke="#00A4FF" stroke-width="2"/>

  </svg>
</template>
