<template>
  <svg style="display: none;">
    <symbol id="comment-1">
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M73.0359 12.9056V2.62305L63.1376 12.9056L1 12.9056L1 57.1212L73.0359 57.1212V19.0753V12.9056Z"
            fill="white"/>
      <path
        d="M73.0359 2.62305H74.0359V0.142295L72.3155 1.92953L73.0359 2.62305ZM63.1376 12.9056V13.9056H63.563L63.8581 13.5992L63.1376 12.9056ZM1 12.9056L1 11.9056H-1.19209e-07L0 12.9056H1ZM1 57.1212H3.69549e-06L3.8147e-06 58.1212L1 58.1212L1 57.1212ZM73.0359 57.1212L73.0359 58.1212H74.0359V57.1212H73.0359ZM72.0359 2.62305V12.9056H74.0359V2.62305H72.0359ZM63.8581 13.5992L73.7564 3.31656L72.3155 1.92953L62.4172 12.2121L63.8581 13.5992ZM1 13.9056L63.1376 13.9056V11.9056L1 11.9056L1 13.9056ZM2 57.1212L2 12.9056H0L3.69549e-06 57.1212L2 57.1212ZM73.0359 56.1212L1 56.1212L1 58.1212L73.0359 58.1212L73.0359 56.1212ZM72.0359 19.0753V57.1212H74.0359V19.0753H72.0359ZM72.0359 12.9056V19.0753H74.0359V12.9056H72.0359Z"
        fill="#5426E2"/>
      <path
        d="M19.2865 38.3232V36.5391L29.5081 32.3291V34.3066L21.8968 37.418L29.5081 40.5381V42.5156L19.2865 38.3232ZM34.8828 45.1787H32.6504L38.7149 29.1914H40.9649L34.8828 45.1787ZM45.0827 42.5156V40.5381L52.694 37.418L45.0827 34.3066V32.3291L55.3044 36.5391V38.3232L45.0827 42.5156Z"
        fill="#5426E2"/>
    </symbol>
  </svg>
</template>
