<template>
  <svg class="home-page__svg" width="1280" height="660" fill="none" xmlns="http://www.w3.org/2000/svg">
    <clouds-python/>
    <CloudsReact y="0" x="0"/>
    <symbol id="screen-1-min" viewBox="0 0 1440 743">
      <circle cx="998" cy="498.5" r="175" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M1064.39 450V495H670.5L632 534H376.5" stroke="#00A4FF" stroke-width="2"/>
      <circle cx="376.5" cy="534" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <circle cx="511.5" cy="700" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <circle cx="580.5" cy="700" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M580.5 700L604.893 672.752H895.5V604" stroke="#00A4FF" stroke-width="2" stroke-dasharray="1 7"/>
      <path d="M511.5 700L579.25 629.928H810.084V553H888.5" stroke="#00A4FF" stroke-width="2" stroke-dasharray="1 7"/>
      <path d="M895.5 462.75L664 459L631.5 494.25H251V436" stroke="#00A4FF" stroke-width="2" stroke-dasharray="1 7"/>
      <path d="M1248.5 488H1397.5V431" stroke="#00A4FF" stroke-width="2"/>
      <circle cx="1397.5" cy="431" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <circle cx="719.5" cy="171" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M718 171.5H791L821.5 202V377L853.5 409H929" stroke="#00A4FF" stroke-width="2"/>
      <path d="M1248.5 450H1349.93V5.5H1440" stroke="#00A4FF" stroke-width="2"/>

      <rect x="510.30786" y="464.92188" width="1.50085" height="9.99374" fill="#C4C4C4"/>
      <rect x="-460.0197754" y="516.6631" width="1.4884" height="10.077" transform="rotate(-90 0.0197754 10.6631)"
            fill="#C4C4C4"/>
      <rect width="2.15205" height="14.4495" transform="matrix(0.710038 -0.704163 0.710038 0.704163 589.869 580.646)"
            fill="#C4C4C4"/>
      <rect width="2.15205" height="14.4495" transform="matrix(-0.710039 -0.704162 0.710039 -0.704162 591.397 590.82)"
            fill="#C4C4C4"/>
      <use xlink:href="#clouds-react" x="1150" y="600" width="100%" height="100%"/>
      <use xlink:href="#clouds-python" x="800" y="0" width="100%" height="100%"/>
    </symbol>

    <symbol id="clouds-min" viewBox="0 0 1280 660">
      <path
        d="M319 1V0H320V1H319ZM255 1H254V0H255V1ZM319 52.3939H320V54.8029L318.294 53.1021L319 52.3939ZM309.273 42.697V41.697H309.686L309.979 41.9888L309.273 42.697ZM255 42.697V43.697H254V42.697H255ZM319 2H255V0H319V2ZM318 36.8788V1H320V36.8788H318ZM318 42.697V36.8788H320V42.697H318ZM318 52.3939V42.697H320V52.3939H318ZM309.979 41.9888L319.706 51.6857L318.294 53.1021L308.567 43.4052L309.979 41.9888ZM255 41.697H309.273V43.697H255V41.697ZM256 1V42.697H254V1H256Z"
        fill="#5426E2"/>
      <circle cx="286.789" cy="22.5766" r="7.87396" fill="#5426E2"/>
      <circle cx="286.789" cy="22.5767" r="1.37794" fill="white"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M274.792 22.4249L277.313 21.7016C277.322 21.0584 277.396 20.4202 277.532 19.7968L275.235 18.5513L275.566 17.5373L278.166 17.8887C278.375 17.428 278.621 16.9831 278.902 16.559L277.215 14.565L277.891 13.7403L280.185 15.0111C280.637 14.5705 281.139 14.1704 281.688 13.8195L280.944 11.3147L281.895 10.8316L283.485 12.9184C283.934 12.7495 284.389 12.6168 284.847 12.5192L285.065 9.90608L286.126 9.80147L286.847 12.312C287.491 12.3141 288.13 12.3818 288.755 12.5118L290.005 10.2051L291.019 10.5361L290.669 13.1259C291.131 13.3299 291.578 13.5708 292.004 13.847L294.006 12.1521L294.831 12.8289L293.566 15.1133C294.011 15.5613 294.417 16.059 294.773 16.6036L297.289 15.8568L297.772 16.8081L295.693 18.3917C295.881 18.8765 296.027 19.3692 296.132 19.8652L298.743 20.0824L298.844 21.1088L296.335 21.8287C296.334 22.4847 296.265 23.1361 296.132 23.7726L298.437 25.0224L298.117 26.0028L295.528 25.653C295.32 26.1264 295.074 26.5835 294.79 27.0193L296.483 29.0197L295.829 29.8168L293.547 28.5524C293.091 29.0074 292.584 29.4209 292.028 29.7834L292.775 32.2969L291.855 32.7638L290.272 30.6864C289.751 30.8901 289.22 31.0446 288.685 31.1516L288.469 33.7522L287.442 33.8533L286.72 31.3345C286.064 31.3267 285.414 31.2511 284.779 31.111L283.534 33.4074L282.554 33.0873L282.905 30.4885C282.433 30.2754 281.977 30.0236 281.544 29.7348L279.552 31.4209L278.755 30.7668L280.024 28.4754C279.574 28.0153 279.166 27.504 278.81 26.9443L276.306 27.6876L275.839 26.7682L277.925 25.1793C277.741 24.6933 277.6 24.1997 277.499 23.703L274.897 23.4865L274.792 22.4249ZM290.031 28.1399C293.519 26.3687 294.911 22.105 293.14 18.6167C291.369 15.1285 287.105 13.7366 283.617 15.5078C280.129 17.2791 278.737 21.5428 280.508 25.031C282.279 28.5193 286.543 29.9112 290.031 28.1399Z"
            fill="#5426E2"/>
      <path
        d="M357 47V46H356V47H357ZM461 47H462V46H461V47ZM357 153H356V155.409L357.706 153.708L357 153ZM377.062 133V132H376.648L376.356 132.292L377.062 133ZM461 133V134H462V133H461ZM357 48H461V46H357V48ZM358 121V47H356V121H358ZM358 133V121H356V133H358ZM358 153V133H356V153H358ZM376.356 132.292L356.294 152.292L357.706 153.708L377.768 133.708L376.356 132.292ZM461 132H377.062V134H461V132ZM460 47V133H462V47H460Z"
        fill="#00A4FF"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M386.06 80.2033L391.197 81.1932C391.793 80.0573 392.502 78.9894 393.307 78.004L390.345 73.7135L391.85 72.209L396.158 75.1827C396.945 74.5529 397.783 73.9845 398.665 73.4847L397.465 68.4139L399.413 67.5585L402.344 71.8902C403.547 71.515 404.801 71.2563 406.093 71.1273L407.033 66H409.161L410.104 71.148C411.055 71.2529 411.984 71.4281 412.887 71.6681L415.633 67.2174L417.616 67.9903L416.63 73.1052C417.773 73.6901 418.848 74.3874 419.842 75.1827L424.15 72.209L425.655 73.7136L422.693 78.0041C423.331 78.7846 423.908 79.6169 424.417 80.4937L429.508 79.2878L430.364 81.2359L426.05 84.1555C426.438 85.3544 426.71 86.6056 426.852 87.8957L432 88.8389V90.9668L426.873 91.9062C426.77 92.938 426.585 93.9456 426.323 94.922L430.771 97.6664L430.024 99.5828L424.912 98.5975C424.318 99.7634 423.607 100.86 422.795 101.871L425.766 106.175L424.312 107.63L420.025 104.67C419.227 105.325 418.374 105.916 417.475 106.436L418.68 111.522L416.796 112.349L413.879 108.039C412.658 108.437 411.383 108.714 410.068 108.857L409.125 114H407.068L406.13 108.877C405.017 108.768 403.933 108.563 402.886 108.271L400.153 112.701L398.237 111.954L399.226 106.821C398.067 106.215 396.978 105.493 395.976 104.67L391.689 107.63L390.234 106.175L393.205 101.871C392.559 101.066 391.977 100.208 391.466 99.3033L386.4 100.503L385.573 98.6196L389.901 95.6908C389.516 94.4661 389.252 93.1878 389.123 91.8701L384 90.9313V88.8744L389.144 87.9319C389.256 86.9012 389.45 85.8954 389.72 84.921L385.287 82.186L386.06 80.2033ZM408 104.129C415.803 104.129 422.129 97.803 422.129 89.9998C422.129 82.1965 415.803 75.8707 408 75.8707C400.197 75.8707 393.871 82.1965 393.871 89.9998C393.871 97.803 400.197 104.129 408 104.129Z"
            fill="#00A4FF"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M157.962 563.981L157.962 547L141.616 563.981L39 563.981L39 637L157.962 637V574.17L157.962 563.981Z"
            fill="white"/>
      <path
        d="M157.962 547L158.962 547V544.519L157.242 546.306L157.962 547ZM157.962 563.981H156.962H157.962ZM141.616 563.981V564.981H142.041L142.336 564.675L141.616 563.981ZM39 563.981L39 562.981H38L38 563.981H39ZM39 637H38L38 638H39L39 637ZM157.962 637V638H158.962V637H157.962ZM157.962 574.17H158.962H157.962ZM156.962 547L156.962 563.981H158.962L158.962 547L156.962 547ZM142.336 564.675L158.683 547.694L157.242 546.306L140.895 563.288L142.336 564.675ZM39 564.981L141.616 564.981V562.981L39 562.981L39 564.981ZM40 637L40 563.981H38L38 637H40ZM157.962 636L39 636L39 638L157.962 638V636ZM156.962 574.17V637H158.962V574.17H156.962ZM156.962 563.981L156.962 574.17H158.962L158.962 563.981H156.962Z"
        fill="#5426E2"/>
      <path
        d="M62.9168 606.545V602.977L83.3602 594.557V598.512L68.1375 604.734L83.3602 610.975V614.93L62.9168 606.545ZM94.1096 620.256H89.6447L101.774 588.281H106.274L94.1096 620.256ZM114.509 614.93V610.975L129.732 604.734L114.509 598.512V594.557L134.953 602.977V606.545L114.509 614.93Z"
        fill="#5426E2"/>
      <rect x="607.534" y="307.856" width="2.16113" height="14.3893" fill="#C4C4C4"/>
      <rect x="601.36" y="316.122" width="2.1431" height="14.5093" transform="rotate(-90 601.36 316.122)"
            fill="#C4C4C4"/>
      <rect x="4.30786" y="4.92188" width="1.50085" height="9.99374" fill="#C4C4C4"/>
      <rect x="0.0197754" y="10.6631" width="1.4884" height="10.077" transform="rotate(-90 0.0197754 10.6631)"
            fill="#C4C4C4"/>
      <rect width="2.15205" height="14.4495" transform="matrix(0.710038 -0.704163 0.710038 0.704163 459.869 204.646)"
            fill="#C4C4C4"/>
      <rect width="2.15205" height="14.4495" transform="matrix(-0.710039 -0.704162 0.710039 -0.704162 461.397 214.82)"
            fill="#C4C4C4"/>
      <rect width="2.15205" height="14.4495" transform="matrix(0.710038 -0.704163 0.710038 0.704163 503.613 538.843)"
            fill="#C4C4C4"/>
      <rect width="2.15204" height="14.4494" transform="matrix(-0.71004 -0.704161 0.71004 -0.704161 505.141 549.018)"
            fill="#C4C4C4"/>
    </symbol>

    <symbol id="comb-1-min" viewBox="0 0 1280 660">
      <use xlink:href="#screen-1-min" x="0" y="0" width="100%" height="100%"/>
      <use xlink:href="#clouds-min" x="690" y="35" width="93%" height="93%"/>
    </symbol>

    <use xlink:href="#comb-1-min" x="0" y="0" width="100%" height="100%"/>
  </svg>
</template>
<script>
import CloudsReact from '../screenElements/CloudsReact'

export default {
  components: {
    CloudsReact
  },
}
</script>
