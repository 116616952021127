import { useSettingsStore } from '~/stores/settings'
import { pageUrl } from '~/helpers/urls'

export const useHomeStore = defineStore('home', {
  state: () => ({
    pageData: {},
    // title: '',
    // blog_items: [],
    // contact_form: {},
    // meta: {
    //   // title: '',
    //   // description: ''
    // },
    // og: {},
  }),
  actions: {
    async setHomePage() {
      const settings = useSettingsStore()

      if (settings?.lang_id) {
        const params1 = {
          type: 'blog.PostPage',
          fields: 'title,year,title_extra,promo_image,likes_count,comments_count,tags',
          descendant_of: settings.lang_id,
          order: '-first_published_at',
          limit: '6',
        }
        const params2 = {
          type: 'home.HomePage',
          fields: '*'
        }
        try {
          const [blog, home] = await Promise.all([
            useApiFetch(pageUrl, { params: params1 }),
            useApiFetch(pageUrl, { params: params2 })
          ])
          const data = home.items[0]
          this.setData(blog, data)
        } catch (err) {
          return err
        }
      }
    },
    setData(blog, data) {
      this.pageData = {
        blog_items: blog.items,
        title: data.title,   
        contact_form: data.content[0].value, 
        meta: {
          title: data.meta.seo_title || data.title, 
          description: data.meta.search_description,
        },
        og: data.og,
      }
    },
    resetState() {
      this.pageData ={}
      // this.blog_items = []
      // this.contact_form = {}
      // this.title = ''
    },
  },
})
