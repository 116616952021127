<template>
  <svg class="home-page__svg" width="1366" height="450" viewBox="0 0 1366 450" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <path d="M10 218 h30 v110 h400 v-130 h425 v-110 h500 " stroke="#00A4FF" stroke-width="2"/>

    <circle cx="110" cy="328" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
    <circle cx="245" cy="328" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
    <circle cx="400" cy="328" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

    <circle cx="535" cy="198" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
    <circle cx="665" cy="198" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
    <circle cx="820" cy="198" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

    <circle cx="970" cy="88" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
    <circle cx="1100" cy="88" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
    <circle cx="1265" cy="88" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

    <path d="M535 198 v-75 h-42 v-107 l15 -15 h282 l15 15 h220 " stroke="#00A4FF" stroke-width="2" stroke-dasharray="1 7"/>
  </svg>
</template>
<script>


</script>
