<template lang="pug">
.full-height
  ScreenMobile(v-if="isMobile.value" :pageData="pageData")
  ScreenNotebook(v-else-if="!isMobile.value && isLowHeightScreen.value" :pageData="pageData")
  ScreenMain(v-else-if="isDesktop.value" :pageData="pageData")
</template>

<script setup>
import { useMediaQuery } from '@vueuse/core'
import { useHomeStore } from '~~/stores/home'
import { mobile, tablet, desktop, lowScreen } from '~/helpers/breakpoints'

const isMobile = reactive({})
const isTablet = reactive({})
const isDesktop = reactive({})
const isLowHeightScreen = reactive({})

definePageMeta({
  layout: 'horizontal',
})

const homeStore = useHomeStore()
await homeStore.setHomePage()
const pageData = homeStore.pageData

usePageHeadMeta(pageData.meta.title, pageData.meta.description, pageData.og)

onMounted(() => {
  isMobile.value = useMediaQuery(mobile)
  isTablet.value = useMediaQuery(tablet)
  isDesktop.value = useMediaQuery(desktop)
  isLowHeightScreen.value = useMediaQuery(lowScreen)
})
</script>

<style lang="scss" scoped>
.full-height {
  height: 100vh;
}
</style>

