<template lang="pug">
div(style="width: 0")
  Bridge.home-bridge_max
  BridgeMin.home-bridge_min
  FooterClouds
#js-scroll.home-container.js-locomotive(data-scroll-container)
  section(data-scroll-section).section
    .home-page.home-main(data-scroll)
      .home-page__content
        div.home-page__cloud-mobile.home-page__cloud-screen(:style="{position: cloudsVuePosition.position, 'margin-top': `${cloudsVuePosition.marginTop}px`, 'margin-left': `${cloudsVuePosition.marginLeft}px`, zIndex: `${cloudsVuePosition.zIndex}` }")
          div(data-scroll data-scroll-speed="-3" )
            CloudsVue
        Screen1.home-page__screen.home-page__screen_max
        Screen1Min.home-page__screen.home-page__screen_min
        picture.home-main__notebook
          source(
            type="image/avif"
            srcset="~/assets/images/home/notebook.avif")
          source(
            type="image/webp"
            srcset="~/assets/images/home/notebook.webp")
          img(
            src="~/assets/images/home/notebook.png"
            alt="")
        .home-main__wrapper
          h1.home-main__title
            | Мы&nbsp;&mdash;&nbsp;
            span.home-main__title_part NE
            | KIDAEM
            br
            | Честная разработка
            br
            | сложных веб-сервисов
          h2.home-main__slogan Вдумчиво. Дорого. На совесть.
          .home-main__subtitles
            p.home-main__subtitle Прозрачная методология работ &#8212; вы видите на&nbsp;каком этапе находится проект и мы всегда на&nbsp;связи.
            p.home-main__subtitle Финансовая ответственность за&nbsp;сорванные сроки и&nbsp;предоставление гарантий.
            p.home-main__subtitle Всегда на связи в рабочее время
        nuxt-link(to="project")
          BaseBtn.home-main__order-btn заказать проект
      svg(class="home-page__right home-page__right_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="10" y="0" width="240" height="100%")
      svg(class="home-page__right home-page__right_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="0" width="160" height="100%")
    .home-page.home-blog(data-scroll)
      svg(class="home-page__left home-page__left_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="-300" y="0" width="240" height="100%")
      svg(class="home-page__left home-page__left_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="0" width="160" height="100%")
      .home-page__content
        Screen2.home-page__screen.home-page__screen_max
        Screen2Min.home-page__screen.home-page__screen_min
        img.lazy__image.home-blog__tooltip(
          data-src="/home/tooltip.svg"
          alt="")
        .home-blog__wrapper
          h2.home-blog__title.home-page__title Блог
          p.home-blog__subtitle
            | Мы рассказываем
            br
            | о своей работе
            br
            | и проектах
        .home-blog__cards
          MediaCard(v-for="post in pageData.blog_items" :key="post.id" :card="post").home-blog__card#blog-card
        NuxtLink(to="blog").home-blog__link
          BaseBtn.home-blog__btn Перейти на страницу блога
      svg(class="home-page__right home-page__right_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="5" y="455" width="240" height="100%")
      svg(class="home-page__right home-page__right_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="455" width="160" height="100%")
    .home-page.home-about(data-scroll)
      svg(class="home-page__left home-page__left_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="-194" y="455" width="240" height="100%")
      svg(class="home-page__left home-page__left_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="455" width="160" height="100%")
      .home-page__content.home-about__content
        Screen3.home-page__screen.home-page__screen_max
        Screen3Min.home-page__screen.home-page__screen_min
        h2.home-about__title.home-page__title О нас
        DialogCard#dialog-text.home-about__dialog-text Мы вместе с вами заботимся о юридической безопасности проекта - охотно заключаем договор и NDA
        DialogCard#dialog-info.home-about__dialog-info(right :css="dialogCardStylesInfo")
          | 35 000 часов
          br
          | в веб-разработке
        .home-about__slider_container
          .home-about__sliders
            .slider__header
              .slider__circle-wrapper
                .slider__circle
                .slider__circle
                .slider__circle
            .slider__container
              .slider__wrapper(:style="{transform: `translateX(${positionSlide}px)` }")
                .home-about__slider.slider__item
                  picture
                    source.lazy__image(
                      type="image/avif"
                      data-srcset="/home/founders.avif")
                    source.lazy__image(
                      type="image/webp"
                      data-srcset="/home/founders.webp")
                    img.lazy__image.slider__img(
                      data-src="/home/founders.png"
                      width="98"
                      height="98"
                      alt="Фото основателей")
                  .slider__text-wrapper
                    h3.slider__title А чем мы подтверждаем свою репутацию?
                    p.slider__text Основатели НеКидаем.рф - самые молодые менторы бизнес-инкубатора и технопарка «Ингрия» в Санкт-Петербурге.
                    p.slider__text Мы помогаем родиться на свет многим интересным проектам.
                    p.slider__text Бизнес-инкубатор «Ингрия» входит в ТОП-10 европейских инкубаторов по версии UBI Global-2015.
                .home-about__slider.slider__item
                  picture
                    source.lazy__image(
                      type="image/avif"
                      data-srcset="/home/founders2.avif")
                    source.lazy__image(
                      type="image/webp"
                      data-srcset="/home/founders2.webp")
                    img.lazy__image.slider__img(
                      data-src="/home/founders2.png"
                      width="98"
                      height="98"
                      alt="Фото основателей")
                  .slider__text-wrapper
                    h3.slider__title Мы охотно делимся своими знаниями и опытом
                    p.slider__text Ежегодно мы бесплатно проводим мастер-классы в магистратуре Санкт-Петербургского Государственного Экономического Университета, в Санкт-Петербургской Государственной консерватории имени Н.А. Римского-Корсакова и на других известных площадках Санкт-Петербурга.
                    p.slider__text Проводим личные консультации для стартапов и успешных бизнесов.
                .home-about__slider.slider__item
                  picture
                    source.lazy__image(
                      type="image/avif"
                      data-srcset="/home/founders3.avif")
                    source.lazy__image(
                      type="image/webp"
                      data-srcset="/home/founders3.webp")
                    img.lazy__image.slider__img(
                      data-src="/home/founders3.png"
                      width="98"
                      height="98"
                      alt="Фото основателей")
                  .slider__text-wrapper
                    h3.slider__title РЕШЕНИЕ КАКИХ ЗАДАЧ НАМ ПО ПЛЕЧУ?
                    p.slider__text Мы успешно справляемся с проектами уровня Sociate.ru и выше. Sociate.ru – это удобный сервис размещения рекламы в социальных сетях.
                    p.slider__text В разработке Sociate.ru мы принимали самое активное участие: технический директор НеКидаем.рф был одним из ведущих разработчиком сервиса.
          .slider__arrows
            img.lazy__image.slider__arrow.slider__arrow_left(
              data-src="/home/arrow.svg" @click="prevSlide()"
              alt="Переход к предыдущему слайду")
            img.lazy__image.slider__arrow.slider__arrow_right(
              data-src="/home/arrow.svg" @click="nextSlide()"
              alt="Переход к следующему слайду")
      svg(class="home-page__right home-page__right_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="10" y="0" width="240" height="100%")
      svg(class="home-page__right home-page__right_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="0" width="160" height="100%")
    .home-page.home-break(data-scroll)
      svg(class="home-page__left home-page__left_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="-250" y="0" width="240" height="100%")
      svg(class="home-page__left home-page__left_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="0" width="160" height="100%")
      .home-page__content
        Screen4.home-page__screen.home-page__screen_max
        Screen4Min.home-page__screen.home-page__screen_min
        h2.home-page__title.home-break__title Что ломает проекты
        .home-break__wrapper
          img.lazy__image.home-break__line(
            data-src="/home/line.svg"
            alt="")
          ul.home-break__list
            li.home-break__list-item
              | Сорванные
              br
              | сроки
            li.home-break__list-item
              | Нет понимания, как вести
              br
              | себя с разработчиками,
              br
              | которые не возвращают
              br
              | вам деньги и ничего
              br
              | не делают по проекту
            li.home-break__list-item
              | Не понятно, как найти опытного
              br
              | и честного веб-разработчика
            li.home-break__list-item
              | Отсутствие понятных
              br
              | результатов
            li.home-break__list-item
              | Отсутствие связи
              br
              | с разработчиками
            li.home-break__list-item
              | Бесконечные
              br
              | форс-мажоры
            li.home-break__list-item
              | Исчезнувшие
              br
              | с деньгами разработчики
        .contact
          DialogCard.contact__header#contact-header(:css="dialogCardBreak" right sm) У вас есть своя «боль»?
          p.contact__info
            | Расскажите нам о ней.
            br
            | Конфиденциально.
            br
            | В благодарность мы дадим
            br
            | бесплатную часовую консультацию.
          Form(:value="pageData.contact_form").contact__form#contact-form
      svg(class="home-page__right home-page__right_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="45" y="504" width="240" height="100%")
      svg(class="home-page__right home-page__right_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="504" width="160" height="100%")
    .home-page.home-advantages(data-scroll)
      svg(class="home-page__left home-page__left_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="-250" y="504" width="240" height="100%")
      svg(class="home-page__left home-page__left_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="504" width="160" height="100%")
      .home-page__content.home-advantags__content
        h2.home-page__title.home-advantages__title
          | Наши
          br
          | преимущества
        Screen5.home-page__screen.home-page__screen_max
        Screen5Min.home-page__screen.home-page__screen_min
        .home-advantages__badge.home-advantages__badge_one
          img.lazy__image.home-advantages__img(:data-src="icons.head" alt="")
          p.home-advantages__text.home-advantages__text_one Ваша нервная система здорова, ведь проект сдан в срок
        .home-advantages__badge.home-advantages__badge_two
          img.lazy__image.home-advantages__img(:data-src="icons.zoom" alt="")
          p.home-advantages__text.home-advantages__text_two Проводим всесторонний аудит вашего проекта
        .home-advantages__badge.home-advantages__badge_three
          img.lazy__image.home-advantages__img(:data-src="icons.window" alt="")
          p.home-advantages__text.home-advantages__text_three Пишем классный, легко читаемый код
        .home-advantages__badge.home-advantages__badge_four
          img.lazy__image.home-advantages__img(:data-src="icons.exit" alt="")
          p.home-advantages__text.home-advantages__text_four
            | Находим выход
            br
            | из (на Ваш взгляд)
            | безвыходных
            br
            | ситуаций
        .home-advantages__badge.home-advantages__badge_five
          img.lazy__image.home-advantages__img(:data-src="icons.calendar" alt="")
          p.home-advantages__text.home-advantages__text_five Ежедневно отвечаем на вопросы о рабочем процессе
        .home-advantages__badge.home-advantages__badge_six
          img.lazy__image.home-advantages__img(:data-src="icons.phone" alt="")
          p.home-advantages__text.home-advantages__text_six
            | Всегда на связи
            | в рабочее
            | время
      svg(class="home-page__right home-page__right_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="10" y="494" width="240" height="100%")
      svg(class="home-page__right home-page__right_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="494" width="160" height="100%")
    .home-page.home-method(data-scroll)
      svg(class="home-page__left home-page__left_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="-5" y="494" width="240" height="100%")
      svg(class="home-page__left home-page__left_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="494" width="160" height="100%")
      .home-page__content.home-method__content
        Screen6.home-page__screen.home-page__screen_max
        Screen6Min.home-page__screen.home-page__screen_min
        h2.home-page__title.home-method__title
          | Наша
          br
          | методология
        .home-method__wrapper
          img.lazy__image.home-method__line(
            data-src="~/assets/images/svg/method.svg"
            alt="")
          .home-method__item.home-method__item_one
            h5.home-method__number 1
            p.home-method__text
              | Оценка
              br
              | текущего положения
              br
              | дел в проекте
          .home-method__item.home-method__item_two
            p.home-method__text Разработка стратегии составления RoadMap.
            h5.home-method__number 2
          .home-method__item.home-method__item_three
            h5.home-method__number 3
            p.home-method__text
              | Декомпозиция
              br
              | по спринтам
          .home-method__item.home-method__item_four
            h5.home-method__number 4
            p.home-method__text
              | Старт.
              br
              | Через две недели
              br
              | показываем
              br
              | первые значимые результаты
          .home-method__item.home-method__item_five
            p.home-method__text
              | Тестирование
              br
              | Правка багов
              br
              | Тестовый
              br
              | релиз
            h5.home-method__number 5
          .home-method__item.home-method__item_six
            h5.home-method__number 6
            p.home-method__text Обратная связь. Учет пожеланий клиента. Старт второго спринта
          .home-method__item.home-method__item_seven
            h5.home-method__number 7
            p.home-method__text Старт третьего, четвертого, пятого, шестого, седьмого... спринтов
          .home-method__item.home-method__item_eight
            p.home-method__text Тестирование. Правка багов. Тестовый релиз
              br
              span Далее работаем итерациями, см. 4-6
            h5.home-method__number 8
          .home-method__item.home-method__item_nine
            h5.home-method__number 9
            p.home-method__text Финальный релиз. Дальнейшее сопровождение проекта
      svg(class="home-page__right home-page__right_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="10" y="225" width="240" height="100%")
      svg(class="home-page__right home-page__right_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="225" width="160" height="100%")
    .home-page.home-link(data-scroll)
      svg(class="home-page__left home-page__left_max" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge" x="-155" y="225" width="240" height="100%")
      svg(class="home-page__left home-page__left_min" width="100%" height="100%" fill="none" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#bridge-min" x="0" y="225" width="160" height="100%")
      .home-page__content
        Screen7.home-page__screen.home-page__screen_max.home-page__screen_max_7
        Screen7Med.home-page__screen.home-page__screen_med.home-page__screen_med_7
        Screen7Min.home-page__screen.home-page__screen_min.home-page__screen_min_7
        picture
          source.lazy__image(
            type="image/avif"
            data-srcset="/home/notebook2.avif")
          source.lazy__image(
            type="image/webp"
            data-srcset="/home/notebook2.webp")
          img.lazy__image.home-link__notebook(
            data-src="/home/notebook2.png"
            alt="")
        svg(class="home-link__footer-clouds"  width="656" height="583" fill="none" viewBox="0 0 656 583" xmlns="http://www.w3.org/2000/svg")
          use(xlink:href="#footer-clouds" x="0" y="0" width="656" height="583")
        .home-link__wrapper
          h1.home-page__title.home-link__title
            | Свяжитесь
            br
            | с нами
          Form(:value="pageData.contact_form").home-link__footer-contact#form-link
          h3.home-link__address
            | Вдумчиво. Дорого. На совесть.
            br
            a(href="tel:+7 (800) 200 38 01").home-link__footer-contact_link +7 800 200 38 01
            br
            a(href="mailto:order@nekidaem.ru").home-link__footer-contact_link order@nekidaem.ru
            br
            | &copy; 2010-2023
.social-media__wrapper
  SocialLinksView
</template>

<script>
// SVG'S --------
import SvgHead from '~/assets/images/svg/icons/head.svg'
import SvgZoom from '~/assets/images/svg/icons/zoom.svg'
import SvgWindow from '~/assets/images/svg/icons/window.svg'
import SvgExit from '~/assets/images/svg/icons/exit.svg'
import SvgPhone from '~/assets/images/svg/icons/phone.svg'
import SvgCalendar from '~/assets/images/svg/icons/calendar.svg'
// SVG'S ^ ------

export default {
  props: {
    pageData: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        head: SvgHead,
        zoom: SvgZoom,
        window: SvgWindow,
        exit: SvgExit,
        phone: SvgPhone,
        calendar: SvgCalendar,
      },
      cloudsVuePosition: {
        position: 'absolute',
        marginTop: 70,
        marginLeft: 150,
        zIndex: 20
      },
      lastScrollY: 0,
      lmS: null,

      positionSlide: 0,
      widthSlideDesktopScreen: 538,
      slideItems: 3
    }
  },
  computed: {
    dialogCardStylesInfo() {
      return {
        textTransform: 'uppercase',
        borderColor: '#5426E2',
        color: '#5426E2',
        fontWeight: 'bold',
      }
    },
    dialogCardBreak() {
      const styles = {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '30px',
      }
      return {
        padding: '23px 48px 18px',
        ...styles,
      }
    },
    widthSlide() {
      return this.widthSlideDesktopScreen
    }
  },
  mounted() {
    // console.log('main')
    this.lmS = new this.$locomotiveScroll({
      el: document.querySelector("#js-scroll"),
      smooth: true,
      direction: 'horizontal',
      getDirection: true,
      getSpeed: true,
    })

    const lazyImages = document.querySelectorAll('.lazy__image')
    this.$setLazyLoading(lazyImages, '.full-height')
  },
  beforeUnmount() {
    this.lmS.destroy()
  },
  methods: {
    nextSlide() {
      if (Math.abs(this.positionSlide) / this.widthSlide < this.slideItems - 1) {
        this.positionSlide -= this.widthSlide
      } else {
        this.positionSlide = 0
      }
    },
    prevSlide() {
      if (Math.abs(this.positionSlide) / this.widthSlide === 0) {
        this.positionSlide = -(this.widthSlide * (this.slideItems - 1))
      } else {
        this.positionSlide += this.widthSlide
      }
    },
  }
}
</script>

<style lang="scss" scoped>
._horizontal-scroll {
  height: 100%;
}

.home-container {
  position: relative;
  height: 100%;

  @include l-desktop {
    flex-direction: row;
  }
}

.social-media {

  &__wrapper {
    width: 100%;
    justify-content: center;
    position: fixed;
    bottom: 12px;
    left: 0;
    display: none;
    z-index: 1000;

    @include l-desktop {
      display: flex;
      justify-content: flex-start;
      left: 20px;
    }

    @media screen and (min-width: $breakpoint-xlarge) and (max-width: $breakpoint-xlarge-max) and (max-height: 800px) {}

    @media (min-width: 1930px) {}
  }

  &__links {
    max-width: 1400px;
    width: 100%;
    position: relative;
    display: flex;
    padding: 0;

    @include md-desktop {
      padding: 0 20px;
    }

    @include l-desktop {
      padding-left: 20px;
    }
  }

  &__item {
    margin-right: 30px;
  }
}

.home-page {
  display: inline-block;
  position: relative;
  vertical-align: top;

  @include mobile {
    display: block;
  }

  @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
    display: block;
  }

  @include md-desktop {
    width: 1360px;
  }

  @media (min-width: 1440px) {
    width: 1440px;
  }

  @media (min-width: 1690px) {
    width: 1520px;
  }

  &__content {
    position: relative;
    width: 100%;
    padding: 34px 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;


    @include mobile {
      align-items: flex-start;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      max-width: 90%;
      padding: 34px 40px;
      align-items: flex-start;
    }

    @include tablet {
      max-width: 60%;
    }

    @include desktop {
      max-width: 50%;
    }

    @media screen and (min-width: $breakpoint-xlarge) and (max-width: $breakpoint-xlarge-max) and (max-height: 800px) {
      margin-top: -50px;
      transform: scale(0.9);
    }

    @include l-desktop {
      padding: 0;
      flex-direction: row;
    }

    @include md-desktop {
      max-width: 1280px;
    }

    @include xl-desktop {
      max-width: 1690px;
      height: 600px;
    }
  }

  &__cloud-mobile {
    @include mobile {
      display: none;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      display: none;
    }
  }

  &__cloud-screen {
    @media (min-width: $breakpoint-notebook) {
      top: 170px;
      left: 650px;
    }
  }

  &__right {
    right: 0;
  }

  &__left {
    left: 0;
  }

  &__right {
    position: absolute;
    height: 100%;
    display: none;

    @include md-desktop {
      height: 743px;
      top: 82px;
    }

    @include xl-desktop {
      height: 734px;
      top: 20px;
      top: 61px;
    }

    &_max {

      @include md-desktop {
        display: none;
      }

      @include xl-desktop {
        width: calc((100vw - 1440px) / 2);
        max-width: 140px;
        display: block;
      }
    }

    &_min {

      @media screen and (min-width: $breakpoint-xlarge) and (max-width: $breakpoint-xlarge-max) and (max-height: 800px) {
        margin-top: -156px;
        width: calc((100vw - 1280px * 0.9) / 2);
        transform: scaleY(0.9);
      }

      @include xl-desktop {
        display: none;
      }
    }
  }

  &__left {
    position: absolute;
    height: 100%;
    display: none;

    @include md-desktop {
      height: 734px;
      top: 82px;
    }

    @include xl-desktop {
      top: 62px;
    }

    &_max {

      @include md-desktop {
        display: none;
      }

      @include xl-desktop {
        width: calc((100vw - 1440px) / 2);
        max-width: 140px;
        display: block;
      }
    }

    &_min {
      @include md-desktop {
        top: -58px;
        left: -50px;
        width: 140px;
        display: block;
      }

      @media screen and (min-width: $breakpoint-xlarge) and (max-width: $breakpoint-xlarge-max) and (max-height: 800px) {
        margin-top: -156px;
        width: calc((100vw - 1280px * 0.9) / 2);
        transform: scaleY(0.9);
      }

      @include xl-desktop {
        display: none;
      }
    }
  }

  &__title {

    @include title-uppercase($primary);
    font-size: 30px;

    @include xl-desktop {
      font-size: 34px;
    }
  }

  &__screen {
    display: none;

    @include md-desktop {
      margin-top: 170px;
    }

    @include l-desktop {
      margin-top: 60px;
    }

    @include xl-desktop {
      margin-top: 265px;
    }

    &_max {
      @include md-desktop {
        display: none;
      }

      @include xl-desktop {
        display: block;
      }

      &_7 {
        @media (min-width: 1440px) and (max-width: 1690px) {
          display: none;
        }
      }
    }

    &_min {
      @include md-desktop {
        display: block;
      }

      @include xl-desktop {
        display: none;
      }

      &_7 {
        @media (min-width: 1440px) and (max-width: 1690px) {
          display: none;
        }
      }
    }

    &_med {
      &_7 {
        @media (min-width: 1440px) and (max-width: 1690px) {
          display: block;
        }
      }
    }
  }
}

.home-main {
  &__header-mb {
    align-self: center;
    margin-bottom: 40px;
    background: no-repeat center url('/home/mobile.svg');
    background-size: cover;
    width: 288px;
    height: 212px;

    @include desktop {
      width: 350px;
      height: 258px;
      margin-bottom: 60px;
    }

    @include l-desktop {
      display: none;
    }
  }

  &__header-inner {
    width: 100%;
  }

  &__notebook {
    display: none;
    position: absolute;
    z-index: 10;

    @include md-desktop {
      display: block;
      width: 34%;
      left: 59%;
      top: 46%;
    }

    @include l-desktop {
      display: block;
      top: 300px;
      left: 760px;
    }

    @include xl-desktop {
      left: 860px;
    }
  }

  &__wrapper {
    margin-bottom: 0px;

    @include mobile {
      // max-width: 335px;
      margin: 0 auto;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      margin: 0 auto;
    }

    @include md-desktop {
      left: 10%;
      top: 200px;
      width: 48%;
    }

    @include l-desktop {
      display: block;
      position: absolute;
      top: 100px;
    }

    @include xl-desktop {
      left: 133px;
      width: 40%;
    }
  }

  &__title {

    @include title-main(21px);
    margin-bottom: 17px;
    width: 100%;

    @include phone {
      font-size: 24px;
    }

    @include tablet {
      font-size: 30px;
    }

    @include desktop {
      font-size: 30px;
    }

    @include md-desktop {
      font-size: 34px;
      margin-bottom: 35px;
    }

    @include xl-desktop {
      font-size: 39px;
      margin-bottom: 32px;
    }

    &_part {
      @include title-uppercase($primary, 21px);

      @include phone {
        font-size: 24px;
      }

      @include tablet {
        font-size: 30px;
      }

      @include desktop {
        font-size: 30px;
      }

      @include md-desktop {
        font-size: 34px;
      }

      @include xl-desktop {
        font-size: 39px;
      }
    }
  }

  &__slogan {
    @include title-rubik;
    color: $primary;
    margin-bottom: 17px;
    font-size: 16px;

    @include desktop {
      font-size: 20px;
    }

    @include l-desktop {
      margin: 15px 0;
      font-size: 22px;
    }
  }

  &__subtitles {

    @include text-small;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 75%;

    @include mobile {
      width: 100%;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      flex-direction: column !important;
    }

    @include desktop {
      width: 100%;
      flex-direction: row;
    }

    @include l-desktop {
      width: 95%;
      flex-direction: row;
    }

    @include xl-desktop {
      width: 100%;
      flex-direction: row;
    }
  }

  &__subtitle {
    margin-bottom: 17px;
    font-size: 14px;
    line-height: 1.4;

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      width: 100% !important;
    }

    @include desktop {
      width: 45%;
      white-space: break-spaces;
    }

    @include l-desktop {
      width: 32%;
      white-space: break-spaces;
    }

    @include xl-desktop {
      width: 45%;
      white-space: break-spaces;
    }
  }

  &__order-btn {

    @include md-desktop {
      top: 540px;
    }

    @include l-desktop {
      display: block;
      left: 130px;
      top: 430px;
      position: absolute;
    }

    @include xl-desktop {
      top: 470px;
    }
  }
}

.home-blog {
  &__tooltip {
    display: none;

    @include md-desktop {
      top: 330px;
      left: 290px;
      width: 107px;
    }

    @include l-desktop {
      position: absolute;
      display: block;
      top: 200px;
    }

    @include xl-desktop {
      top: 193px;
      left: 288px;
    }
  }

  &__wrapper {
    margin-left: 0px;
    margin-bottom: 24px;

    @include md-desktop {
      position: absolute;
      top: 75px;
      left: 145px;
    }

    @include l-desktop {
      position: absolute;
      top: 45px;
      left: 145px;
    }

    @include xl-desktop {
      position: absolute;
      top: 40px;
      left: 142px;
    }
  }

  &__subtitle {

    @include subtitle($primary);
    /*width: 60%;*/

    @include l-desktop {
      /*width: 40%;*/
    }
  }

  &__cards {

    @include l-desktop {
      position: absolute;
      left: 518px;
      top: 40px;
      width: 690px;
      display: grid;
      grid-gap: 20px;
      grid-template-columns: repeat(3, 1fr);
    }
  }

  &__card#blog-card {
    margin-bottom: 40px;

    @include l-desktop {
      margin-bottom: 0;
    }
  }

  @include l-desktop {
    &__card :deep(a>p) {
      width: 215px;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
      margin-bottom: 0px;
    }
  }

  &__link {
    width: 100%;

    @include l-desktop {
      width: auto;
      position: absolute;
      top: -109px;
    }

  }

  &__btn {

    @include l-desktop {
      position: absolute;
    }

    @include md-desktop {
      left: 138px;
      top: 636px;
    }

    @include l-desktop {
      left: 138px;
      top: 600px;
    }

    @include xl-desktop {
      left: 138px;
      top: 600px;
    }
  }
}

.home-about {
  position: relative;

  &__title {
    display: none;

    @include l-desktop {
      display: block;
      position: absolute;
    }

    @include md-desktop {
      top: 75px;
      left: 145px;
    }

    @include l-desktop {
      top: 45px;
      left: 145px;
    }

    @include xl-desktop {
      top: 45px;
      left: 140px;
    }
  }

  &__dialog-text#dialog-text,
  &__dialog-info#dialog-info {
    margin-bottom: 100px;

    @include l-desktop {
      position: absolute;
      z-index: 10;
    }
  }

  &__dialog-text#dialog-text {
    padding: 21px 42px;
    margin-bottom: 50px;

    @include l-desktop {
      top: 240px;
      left: 138px;
      padding: 33px;
    }

  }

  &__dialog-info#dialog-info {
    max-width: 253px;
    font-size: 22px;
    padding: 19px 10px 19px 22px;
    border-color: #5426E2;
    color: #5426E2;
    align-self: flex-end;
    line-height: 1.36;

    @include l-desktop {
      padding: 25px 16px 23px 24px;
      font-size: 30px;
      max-width: 335px;
      top: 460px;
      left: 372px;
    }
  }

  &__slider_container {
    position: relative;
    border: 2px solid $primary;
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    padding: 65px 27px;

    @include mobile {
      margin: 0 auto;
      width: 100%;
    }

    @media (max-width: 350px) {
      padding: 65px 17px;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      margin: 0 auto;
      width: 100%;
    }

    @include md-desktop {
      top: 211px;
      left: 612px;
      max-width: 590px;
      height: 580px;
      padding: 109px 76px 39px;
    }

    @include l-desktop {
      position: absolute;
      flex-direction: row;
      height: 604px;
      top: 80px;
    }

    @include xl-desktop {
      top: 80px;
      left: 610px;
      max-width: 690px;
      height: 580px;
      padding: 129px 96px 59px;
    }

  }
}

.slider {

  &__container {
    overflow: hidden;

    @include mobile {
      width: 330px;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      width: 350px;
    }

    @include l-desktop {
      width: 538px;
    }

    @media (max-width: 350px) {
      width: 260px;
    }
  }

  &__wrapper {
    display: flex;
    transition: transform 250ms;
  }

  &__item {
    @include mobile {
      flex-basis: 330px;
      flex-shrink: 0;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      flex-basis: 350px;
      flex-shrink: 0;
    }

    @include l-desktop {
      flex-basis: 538px;
      flex-shrink: 0;
      display: flex;
    }

  }


  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 37px;
    background: #eaeaea;
    border-bottom: 2px solid $primary;
  }

  &__circle-wrapper {
    display: none;

    @include l-desktop {
      position: absolute;
      display: block;
      top: 0;
      right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 56px;
      height: 100%;
    }
  }

  &__circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: $primary;
  }

  &__title {

    @include title-uppercase($primary, 20px);
    margin-bottom: 15px;

    @media (max-width: 350px) {
      font-size: 16px;
    }
  }

  &__img {
    min-width: 98px;
    height: 98px;
    border-radius: 50%;
    background-color: #c4c4c4;
    margin-right: 20px;
  }

  &__text-wrapper {
    padding-right: 25px;
    margin-top: 30px;

    @media (max-width: 350px) {
      padding-right: 10px;
      max-width: 260px;
    }

    @include l-desktop {
      margin-top: 0;
      max-width: 360px;
    }

    @include xl-desktop {
      margin-top: 0;
      max-width: 500px;
    }
  }

  &__text {
    font-size: 18px;
    margin-bottom: 20px;

    @include mobile {
      font-size: 16px;
    }

    @include l-desktop {
      font-size: 18px;
    }
  }

  &__arrow {
    position: absolute;
    top: 90%;
    cursor: pointer;

    @include l-desktop {
      top: 52%;
    }

    &_left {
      transform: rotate(180deg);
      left: 5%;

      @include l-desktop {
        left: 16%;
      }
    }

    &_right {
      right: 8%;
    }
  }
}

.home-break {

  &__title {
    @include md-desktop {
      top: 75px;
      left: 170px;
    }

    @include l-desktop {
      position: absolute;
      top: 45px;
    }

    @include xl-desktop {
      top: 45px;
      left: 140px;
    }
  }

  &__wrapper {
    @include mobile {
      display: flex;
      padding-left: 15px;
      margin-bottom: 30px;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      display: flex;
      padding-left: 15px;
      margin-bottom: 30px;
    }
  }

  &__line {
    margin-top: -55px;

    @include l-desktop {
      display: none;
    }
  }

  &__list {

    @include mobile {
      padding-left: 25px;
      padding-top: 60px;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      padding-left: 25px;
      padding-top: 60px;
    }

    @include md-desktop {
      top: 157px;
      left: 420px;
    }

    @include l-desktop {
      position: absolute;
      top: 135px;
    }

    @include xl-desktop {
      top: 138px;
      left: 375px;
    }
  }

  &__list-item {
    line-height: 1.4;
    margin-bottom: 26px;

    @include l-desktop {
      margin-bottom: 24px;

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(5) {
        margin-bottom: 43px;
      }

      &:nth-child(4) {
        margin-bottom: 24px;
      }
    }
  }
}

.contact {
  display: flex;
  flex-direction: column;

  @include md-desktop {
    top: 89px;
    left: 850px;
  }

  @include l-desktop {
    position: absolute;
    top: 59px;
  }

  @include xl-desktop {
    top: 56px;
    left: 850px;
  }

  &__header#contact-header {

    @include mobile {
      width: 157px;
      align-self: flex-end;
      margin-right: 70px;
      margin-bottom: 30px;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      width: 157px;
      align-self: flex-end;
      margin-right: 70px;
      margin-bottom: 30px;
    }

    @include l-desktop {
      margin-bottom: 44px;
    }
  }

  &__info {
    @include text;

    @include mobile {
      padding-left: 15px;
      margin-bottom: 40px;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      margin-bottom: 40px;
      padding-left: 5px;
    }

    @include l-desktop {
      font-size: 16px;
      margin-bottom: 38px;
    }
  }

  &__form#contact-form {
    @include mobile {
      // max-width: 335px;

      .base-btn {
        width: 100%;
        // max-width: 335px;
      }
    }

    @include l-desktop {
      width: 336px;
    }
  }

  @include l-desktop {
    &__form#contact-form :deep(>.wagtail-form__submitted-title) {
      margin-top: 170px;
    }
  }
}

.home-advantages {

  &__title {
    width: 100%;

    @include mobile {
      margin-bottom: 100px;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      margin-bottom: 100px;
    }

    @include md-desktop {
      top: 75px;
      left: 170px;
    }

    @include l-desktop {
      position: absolute;
      top: 45px;
    }

    @include xl-desktop {
      top: 50px;
      left: 126px;
    }
  }

  &__badge {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;

    @include mobile {
      margin-bottom: 80px;

      &_one,
      &_three,
      &_five {
        .home-advantages__img {
          margin-right: 30px;
        }
      }

      &_two,
      &_four,
      &_six {
        flex-direction: row-reverse;

        .home-advantages__img {
          margin-left: 30px;
        }
      }
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      width: 360px;
      justify-content: center;
      margin-bottom: 80px;

      &_one,
      &_three,
      &_five {
        .home-advantages__img {
          margin-right: 30px;
        }
      }

      &_two,
      &_four,
      &_six {
        flex-direction: row-reverse;

        .home-advantages__img {
          margin-left: 30px;
        }
      }
    }

    @include tablet {
      width: 360px;
      justify-content: center;
    }

    @include md-desktop {
      &_one {
        top: 240px;
        left: 210px;
      }

      &_two {
        top: 415px;
        left: 305px;
      }

      &_three {
        top: 615px;
        left: 205px;
        width: 290px;
      }

      &_four {
        top: 230px;
        left: 695px;
      }

      &_five {
        top: 215px;
        left: 1095px;
      }

      &_six {
        top: 480px;
        left: 840px;
        width: 270px;
      }
    }

    @include l-desktop {
      position: absolute;
      width: 290px;

      &_one {
        top: 200px;
        left: 200px;
      }

      &_two {
        top: 390px;
        left: 310px;
      }

      &_two :deep(>img) {
        margin-right: 0;
      }

      &_three {
        top: 590px;
        left: 205px;
      }

      &_four {
        top: 190px;
        left: 695px;
      }

      &_four :deep(>img) {
        margin-right: 0;
      }

      &_five {
        top: 180px;
        left: 1095px;
      }

      &_five :deep(>img) {
        margin-right: 0;
      }

      &_six {
        top: 445px;
        left: 840px;
        width: 270px;
      }

    }

    @include xl-desktop {
      &_one {
        top: 185px;
        left: 200px;
      }

      &_two {
        top: 383px;
        left: 310px;
      }

      &_three {
        top: 583px;
        left: 200px;
        width: 300px;
      }

      &_four {
        top: 190px;
        left: 693px;
      }

      &_five {
        top: 170px;
        left: 1090px;
        width: 300px;
      }

      &_six {
        top: 430px;
        left: 830px;
        width: 270px;
      }
    }
  }

  &__img {

    @include md-desktop {
      width: 120px;
      height: 120px;
      margin-right: 8px;
    }

    @include l-desktop {
      width: 130px;
      height: 130px;
    }

    @include xl-desktop {
      width: 140px;
      height: 140px;
    }
  }

  &__text {
    color: #000;
    line-height: 1.4;
    margin-bottom: 0;

    @include l-desktop {
      align-self: flex-start;
      margin-top: 12px;

      &_three {
        margin-top: 22px;
      }

      &_five {
        margin-top: 22px;
      }

      &_six {
        margin-top: 32px;
      }
    }

    @include xl-desktop {
      align-self: flex-start;
      margin-top: 22px;

      &_three {
        margin-top: 32px;
      }

      &_five {
        margin-top: 32px;
      }

      &_six {
        margin-top: 32px;
      }
    }
  }
}

.home-method {
  &__content {
    align-items: center;

    @include l-desktop {
      width: auto;
      align-items: flex-start;
    }

    @include xl-desktop {
      align-items: center;
    }
  }

  &__wrapper {
    margin: 0 auto;

    @include mobile {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 335px;
      padding-left: 10px;
    }

    @media (max-width: 335px) {
      padding-left: 15px !important;
      width: 305px;
    }

    @include phone {
      margin: 0;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      width: 345px;
      padding-left: 10px;
    }

    @include tablet {
      width: 345px;
    }

    @media (max-width: 350px) {
      padding-left: 0px;
    }
  }

  &__title {
    width: 100%;

    @include mobile {
      margin-bottom: 80px;
      width: 95%;

      // @media (max-width: 370px) {
      //    width: 280px;
      // }
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      margin-bottom: 80px;
      width: 100%;
    }

    @include tablet {
      width: 335px;
    }

    @include md-desktop {
      top: 75px;
      left: 108px;
    }

    @include l-desktop {
      position: absolute;
      top: 45px;
    }

    @include xl-desktop {
      top: 50px;
      left: 128px;
    }
  }

  &__line {
    position: absolute;
    margin-top: 15px;
    width: 320px;

    @include phone {
      width: 100%;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      margin-left: -25px;
    }

    @media (max-width: 350px) {
      width: 280px;
    }

    @include l-desktop {
      display: none;
      width: auto;
    }
  }

  &__item>p {
    @media (max-width: 350px) {
      font-size: 12px;
    }
  }

  &__item {
    line-height: 1.4;
    width: 160px;
    margin-bottom: 45px;

    &:nth-child(odd) {
      padding-left: 10px;
    }

    &:nth-child(even) {
      @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
        margin-left: -25px;
      }
    }

    &:nth-child(2),
    &:nth-child(6) {
      margin-bottom: 60px;
    }

    &:nth-child(2) {
      @media (max-width: 350px) {
        margin-bottom: 76px;
      }
    }

    &:nth-child(5) {
      @media (max-width: 350px) {
        margin-bottom: 40px;
      }
    }

    &:nth-child(6) {
      @media (max-width: 350px) {
        margin-bottom: 60px;
      }
    }

    @include phone {
      margin-bottom: 54px;
      width: 165px;

      &:nth-child(odd) {
        padding-left: 15px;
      }

      &:nth-child(2),
      &:nth-child(6) {
        margin-bottom: 68px;
      }
    }

    @include tablet {

      &:nth-child(2),
      &:nth-child(6) {
        margin-bottom: 78px;
      }
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {

      &:nth-child(2),
      &:nth-child(6) {
        margin-bottom: 78px;
      }
    }

    @media (max-width: 350px) {
      width: 135px;
      margin-bottom: 30px;
    }

    @include md-desktop {

      &_one {
        top: 540px;
        left: 29px;

        .home-method__number {
          margin-bottom: 30px;
        }
      }

      &_two {
        top: 480px;
        left: 148px;

        .home-method__text {
          margin-bottom: 30px;
        }
      }

      &_three {
        top: 540px;
        left: 267px;

        .home-method__number {
          margin-bottom: 30px;
        }
      }

      &_four {
        top: 465px;
        left: 475px;

        .home-method__number {
          margin-bottom: 10px;
        }
      }

      &_five {
        top: 290px;
        left: 592px;

        .home-method__text {
          margin-bottom: 10px;
        }
      }

      &_six {
        top: 465px;
        left: 710px;

        .home-method__number {
          margin-bottom: 10px;
        }
      }

      &_seven {
        top: 325px;
        left: 910px;

        .home-method__number {
          margin-bottom: 10px;
        }
      }

      &_eight {
        top: 140px;
        left: 1025px;

        .home-method__text {
          margin-bottom: 10px;
        }
      }

      &_nine {
        top: 325px;
        left: 1145px;

        .home-method__number {
          margin-bottom: 10px;
        }
      }
    }

    @include l-desktop {
      position: absolute;
      width: 158px;
      margin: 0 20px 40px;

      &:nth-child(odd) {
        padding-left: 0;
      }

      &_one {
        top: 510px;
        left: 29px;
      }

      &_two {
        top: 450px;
        left: 148px;
      }

      &_three {
        top: 510px;
        left: 267px;
      }

      &_four {
        top: 425px;
        left: 475px;
      }

      &_five {
        top: 270px;
        left: 592px;
      }

      &_six {
        top: 425px;
        left: 710px;
      }

      &_seven {
        top: 295px;
        left: 910px;

      }

      &_eight {
        top: 125px;
        left: 1025px;
      }

      &_nine {
        top: 300px;
        left: 1145px;
      }
    }

    @include xl-desktop {
      &_one {
        top: 510px;
        left: 129px;

        .home-method__number {
          margin-bottom: 30px;
        }
      }

      &_two {
        top: 455px;
        left: 248px;

        .home-method__text {
          margin-bottom: 26px;
        }
      }

      &_three {
        top: 510px;
        left: 367px;

        .home-method__number {
          margin-bottom: 30px;
        }
      }

      &_four {
        top: 425px;
        left: 575px;

        .home-method__number {
          margin-bottom: 0;
        }
      }

      &_five {
        top: 275px;
        left: 692px;

        .home-method__text {
          margin-bottom: 5px;
        }
      }

      &_six {
        top: 425px;
        left: 820px;

        .home-method__number {
          margin-bottom: 0;
        }
      }

      &_seven {
        top: 300px;
        left: 1010px;

        .home-method__number {
          margin-bottom: 10px;
        }
      }

      &_eight {
        top: 135px;
        left: 1130px;

        .home-method__text {
          margin-bottom: -5px;
        }
      }

      &_nine {
        top: 295px;
        left: 1250px;

        .home-method__number {
          margin-bottom: 10px;
        }
      }
    }
  }

  &__number {
    font-size: 30px;
    color: $primary;
    font-weight: bold;
    margin-bottom: 0;
  }

  &__text {
    line-height: 1.38;

    @include l-desktop {
      padding-left: 4px;
    }
  }

  &__text>span {
    font-size: 13px;
    line-height: 1;

  }
}

.home-link {
  @include l-desktop {
    padding-right: 20px;
  }

  &__wrapper {

    @include md-desktop {
      left: 860px;
      top: 110px;
    }

    @include l-desktop {
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      position: absolute;
      z-index: 1000;
      width: 335px;
      top: 155px;
    }

    @media (min-width: 1280px) and (max-width: 1440px) {
      left: 880px;
    }

    @media (min-width: 1440px) and (max-width: 1690px) {
      left: 980px;
    }

    @media (min-width: 1690px) {
      left: 1000px;
    }

    @include xl-desktop {
      top: 180px;
    }
  }

  &__notebook,
  &__footer-clouds {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__footer-clouds {
    width: 290px;
    top: -125px;

    @include phone {
      width: 400px;
      top: -60px;
    }

    @include tablet {
      width: 530px;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      width: 530px;
    }

    @include desktop {
      width: 530px;
    }

    @include l-desktop {
      width: auto;
      top: 15px;
      left: 160px;
      transform: none;
    }
  }

  &__notebook {
    top: 90px;
    width: 220px;

    @include phone {
      top: 130px;
      width: 300px;
    }

    @include tablet {
      top: 100px;
      width: 390px;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      top: 100px;
      width: 390px;
    }

    @include desktop {
      top: 100px;
      width: 390px;
    }

    @include l-desktop {
      width: 477px;
      top: 135px;
      left: 244px;
      transform: none;
    }
  }

  &__title {
    @include mobile {
      display: none;
    }

    @media only screen and (width: 1024px) and (max-height: 1366px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1.5) {
      display: none;
    }

    @include xl-desktop {
      margin-bottom: 42px;
    }
  }

  &__footer-contact#form-link {

    @media screen and (max-width: $breakpoint-mini-max) {
      margin-top: 300px;
    }

    @include phone {
      margin-top: 550px;
      // max-width: 335px;
    }

    @include l-desktop {
      margin-top: 0;
    }
  }

  &__footer-contact {
    &_link {
      color: $primary;
      text-decoration: none;
    }
  }

  &__address {
    margin-top: 80px;

    @include subtitle($primary, 16px, 700);

    @include l-desktop {
      display: none;
    }
  }
}

.home-bridge {
  &_min {
    @include md-desktop {
      display: block;
    }

    @include xl-desktop {
      display: none;
    }

  }

  &_max {
    @include md-desktop {
      display: none;
    }

    @include xl-desktop {
      display: block;
    }
  }
}

div,
p {
  white-space: break-spaces;
}
</style>
