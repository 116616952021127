<template lang="pug">
FooterClouds
section.section
  .home-page.home-main
    .home-page__content
      div.home-main__header-mb
        picture
          source(type="image/avif" srcset="/home/mobile-screen.avif")
          source(type="image/webp" srcset="/home/mobile-screen.webp")
          img.home-main__header-inner(
            src="/home/mobile-screen.png"
            alt=""
            width="288"
            height="211")
      .home-main__wrapper
        h1.home-main__title
          | Мы&nbsp;&mdash;&nbsp;
          span.home-main__title_part NE
          | KIDAEM
          br
          | Честная разработка
          br
          | сложных веб-сервисов
        h2.home-main__slogan Вдумчиво. Дорого. На совесть.
        .home-main__subtitles
          p.home-main__subtitle Прозрачная методология работ &#8212; вы видите на&nbsp;каком этапе находится проект и мы всегда на&nbsp;связи.
          p.home-main__subtitle Финансовая ответственность за&nbsp;сорванные сроки и&nbsp;предоставление гарантий.
          //- p.home-main__subtitle Всегда на связи в рабочее время
      NuxtLink(to="project")
        BaseBtn.home-main__order-btn заказать проект
  .home-page.home-blog
    .home-page__content
      .home-blog__wrapper
        h2.home-blog__title.home-page__title Блог
        p.home-blog__subtitle
          | Мы рассказываем
          br
          | о своей работе
          br
          | и проектах
      .home-blog__cards
        MediaCard(v-for="post in pageData.blog_items" :key="post.id" :card="post").home-blog__card#blog-card
      nuxt-link(to="blog").home-blog__link
        BaseBtn.home-blog__btn Перейти в блог
  .home-page.home-about
    .home-page__content.home-about__content
      DialogCard#dialog-text.home-about__dialog-text Мы вместе с вами заботимся о юридической безопасности проекта - охотно заключаем договор и NDA
      DialogCard#dialog-info.home-about__dialog-info(right :css="dialogCardStylesInfo")
        | 35 000 часов
        br
        | в веб-разработке
      .home-about__slider_container
        .home-about__sliders
          .slider__header
          .slider__container
            .slider__wrapper(:style="{transform: `translateX(${positionSlide}px)` }")
              .home-about__slider.slider__item
                picture
                  source.lazy__image(
                    type="image/avif"
                    data-srcset="/home/founders.avif")
                  source.lazy__image(
                    type="image/webp"
                    data-srcset="/home/founders.webp")
                  img.lazy__image.slider__img(
                    data-src="/home/founders.png"
                    width="98"
                    height="98"
                    alt="Фото основателей")
                .slider__text-wrapper
                  h3.slider__title А чем мы подтверждаем свою репутацию?
                  p.slider__text Основатели НеКидаем.рф - самые молодые менторы бизнес-инкубатора и технопарка «Ингрия» в Санкт-Петербурге.
                  p.slider__text Мы помогаем родиться на свет многим интересным проектам.
                  p.slider__text Бизнес-инкубатор «Ингрия» входит в ТОП-10 европейских инкубаторов по версии UBI Global-2015.
              .home-about__slider.slider__item
                picture
                  source.lazy__image(
                    type="image/avif"
                    data-srcset="/home/founders2.avif")
                  source.lazy__image(
                    type="image/webp"
                    data-srcset="/home/founders2.webp")
                  img.lazy__image.slider__img(
                    data-src="/home/founders2.png"
                    width="98"
                    height="98"
                    alt="Фото основателей")
                .slider__text-wrapper
                  h3.slider__title Мы охотно делимся своими знаниями и опытом
                  p.slider__text Ежегодно мы бесплатно проводим мастер-классы в магистратуре Санкт-Петербургского Государственного Экономического Университета, в Санкт-Петербургской Государственной консерватории имени Н.А. Римского-Корсакова и на других известных площадках Санкт-Петербурга.
                  p.slider__text Проводим личные консультации для стартапов и успешных бизнесов.
              .home-about__slider.slider__item
                picture
                  source.lazy__image(
                    type="image/avif"
                    data-srcset="/home/founders3.avif")
                  source.lazy__image(
                    type="image/webp"
                    data-srcset="/home/founders3.webp")
                  img.lazy__image.slider__img(
                    data-src="/home/founders3.png"
                    width="98"
                    height="98"
                    alt="Фото основателей")
                .slider__text-wrapper
                  h3.slider__title РЕШЕНИЕ КАКИХ ЗАДАЧ НАМ ПО ПЛЕЧУ?
                  p.slider__text Мы успешно справляемся с проектами уровня Sociate.ru и выше. Sociate.ru – это удобный сервис размещения рекламы в социальных сетях.
                  p.slider__text В разработке Sociate.ru мы принимали самое активное участие: технический директор НеКидаем.рф был одним из ведущих разработчиком сервиса.
        .slider__arrows
          img.lazy__image.slider__arrow.slider__arrow_left(
            data-src="/home/arrow.svg" @click="prevSlide()"
            alt="Переход к предыдущему слайду")
          img.lazy__image.slider__arrow.slider__arrow_right(
            data-src="/home/arrow.svg" @click="nextSlide()"
            alt="Переход к следующему слайду")
  .home-page.home-break
    .home-page__content
      h2.home-page__title.home-break__title Что ломает проекты
      .home-break__wrapper
        ul.home-break__list
          li.home-break__list-item
            | Сорванные
            br
            | сроки
          li.home-break__list-item
            | Нет понимания, как вести
            br
            | себя с разработчиками,
            br
            | которые не возвращают
            br
            | вам деньги и ничего
            br
            | не делают по проекту
          li.home-break__list-item
            | Не понятно, как найти опытного
            br
            | и честного веб-разработчика
          li.home-break__list-item
            | Отсутствие понятных
            br
            | результатов
          li.home-break__list-item
            | Отсутствие связи
            br
            | с разработчиками
          li.home-break__list-item
            | Бесконечные
            br
            | форс-мажоры
          li.home-break__list-item
            | Исчезнувшие
            br
            | с деньгами разработчики
      .contact
        DialogCard.contact__header#contact-header(:css="dialogCardBreak" right sm) Боль
        p.contact__info
          | Расскажите нам о ней.
          br
          | Конфиденциально.
          br
          | В благодарность мы дадим
          br
          | бесплатную часовую консультацию.
        Form(:value="pageData.contact_form").contact__form#contact-form
  .home-page.home-advantages
    .home-page__content.home-advantags__content
      h2.home-page__title.home-advantages__title
        | Наши
        br
        | преимущества
      .home-advantages__badge.home-advantages__badge_one
        img.lazy__image.home-advantages__img(:data-src="icons.head" alt="")
        p.home-advantages__text.home-advantages__text_one Ваша нервная система здорова, ведь проект сдан в срок
      .home-advantages__badge.home-advantages__badge_two
        img.lazy__image.home-advantages__img(:data-src="icons.zoom" alt="")
        p.home-advantages__text.home-advantages__text_two Проводим всесторонний аудит вашего проекта
      .home-advantages__badge.home-advantages__badge_three
        img.lazy__image.home-advantages__img(:data-src="icons.window" alt="")
        p.home-advantages__text.home-advantages__text_three Пишем классный, легко читаемый код
      .home-advantages__badge.home-advantages__badge_four
        img.lazy__image.home-advantages__img(:data-src="icons.exit" alt="")
        p.home-advantages__text.home-advantages__text_four
          | Находим выход
          br
          | из (на Ваш взгляд)
          | безвыходных
          br
          | ситуаций
      .home-advantages__badge.home-advantages__badge_five
        img.lazy__image.home-advantages__img(:data-src="icons.calendar" alt="")
        p.home-advantages__text.home-advantages__text_five Ежедневно отвечаем на вопросы о рабочем процессе
      .home-advantages__badge.home-advantages__badge_six
        img.lazy__image.home-advantages__img(:data-src="icons.phone" alt="")
        p.home-advantages__text.home-advantages__text_six
          | Всегда на связи
          | в рабочее
          | время
  .home-page.home-method
    .home-page__content.home-method__content
      h2.home-page__title.home-method__title
        | Наша
        br
        | методология
      .home-method__wrapper
        .home-method__item.home-method__item_one
          h5.home-method__number 1
          p.home-method__text
            | Оценка
            br
            | текущего положения
            br
            | дел в проекте
        .home-method__item.home-method__item_two
          h5.home-method__number 2
          p.home-method__text Разработка стратегии составления RoadMap.
        .home-method__middle
        .home-method__item.home-method__item_three
          h5.home-method__number 3
          p.home-method__text
            | Декомпозиция
            br
            | по спринтам
        .home-method__item.home-method__item_four
          h5.home-method__number 4
          p.home-method__text
            | Старт.
            br
            | Через две недели
            br
            | показываем
            br
            | первые значимые результаты
        .home-method__middle
        .home-method__item.home-method__item_five
          h5.home-method__number 5
          p.home-method__text
            | Тестирование
            br
            | Правка багов
            br
            | Тестовый
            br
            | релиз
        .home-method__item.home-method__item_six
          h5.home-method__number 6
          p.home-method__text Обратная связь. Учет пожеланий клиента. Старт второго спринта
        .home-method__middle
        .home-method__item.home-method__item_seven
          h5.home-method__number 7
          p.home-method__text Старт третьего, четвертого, пятого, шестого, седьмого... спринтов
        .home-method__item.home-method__item_eight
          h5.home-method__number 8
          p.home-method__text Тестирование. Правка багов. Тестовый релиз
            br
            span Далее работаем итерациями, см. 4-6
        .home-method__middle
        .home-method__item.home-method__item_nine
          h5.home-method__number 9
          p.home-method__text Финальный релиз. Дальнейшее сопровождение проекта
  .home-page.home-link
    .home-page__content
      picture
        source.lazy__image(
          type="image/avif"
          data-srcset="/home/notebook2.avif")
        source.lazy__image(
          type="image/webp"
          data-srcset="/home/notebook2.webp")
        img.lazy__image.home-link__notebook(
          data-src="~/assets/images/home/notebook2.png"
          alt=""
          width="220"
          height="159")
      svg(class="home-link__footer-clouds"  width="530" height="583" fill="none" viewBox="0 0 656 583" xmlns="http://www.w3.org/2000/svg")
        use(xlink:href="#footer-clouds" x="0" y="0")
      .home-link__wrapper
        Form(:value="pageData.contact_form").home-link__footer-contact#form-link
        h3.home-link__address
          | Вдумчиво. Дорого. На совесть.
          br
          a(href="tel:+7 (800) 200 38 01").home-link__footer-contact_link +7 800 200 38 01
          br
          a(href="mailto:order@nekidaem.ru").home-link__footer-contact_link order@nekidaem.ru
          br
          | &copy; 2010-2023
</template>

<script>
// SVG'S --------
import SvgHead from "~/assets/images/svg/icons/head.svg";
import SvgZoom from "~/assets/images/svg/icons/zoom.svg";
import SvgWindow from "~/assets/images/svg/icons/window.svg";
import SvgExit from "~/assets/images/svg/icons/exit.svg";
import SvgPhone from "~/assets/images/svg/icons/phone.svg";
import SvgCalendar from "~/assets/images/svg/icons/calendar.svg";
// SVG'S ^ ------

export default {
  props: {
    pageData: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        head: SvgHead,
        zoom: SvgZoom,
        window: SvgWindow,
        exit: SvgExit,
        phone: SvgPhone,
        calendar: SvgCalendar,
      },

      positionSlide: 0,
      widthSlideMobileScreen: 330,
      slideItems: 3,
    };
  },
  head() {
    return {
      link: [
        { rel: 'preload', as: 'image', href: '/home/mobile.svg', type: 'image/svg+xml' },
        { rel: 'preload', as: 'image', href: '/home/mobile-screen.avif', type: 'image/avif' },
        // { rel: 'preload', as: 'image', href: '/home/mobile-screen.webp', type: 'image/webp' },
        // { rel: 'preload', as: 'image', href: '/home/mobile-screen.png' },
      ],
    }
  },
  computed: {
    dialogCardStylesInfo() {
      return {
        textTransform: "uppercase",
        borderColor: "#5426E2",
        color: "#5426E2",
        fontWeight: "bold",
      };
    },
    dialogCardBreak() {
      const styles = {
        fontWeight: "bold",
        textTransform: "uppercase",
        fontSize: "30px",
      };

      return {
        padding: "16px 37px",
        ...styles,
      }
    },
    widthSlide() {
      return this.widthSlideMobileScreen
    },
  },
  mounted() {
    // console.log('mobile')
    const lazyImages = document.querySelectorAll(".lazy__image")
    this.$setLazyLoading(lazyImages, null)
  },
  methods: {
    //TODO: отрефакторить. Вычислять ширину слайда. Слайды перенести в массив.
    nextSlide() {
      if (
        Math.abs(this.positionSlide) / this.widthSlide <
        this.slideItems - 1
      ) {
        this.positionSlide -= this.widthSlide;
      } else {
        this.positionSlide = 0;
      }
    },
    prevSlide() {
      if (Math.abs(this.positionSlide) / this.widthSlide === 0) {
        this.positionSlide = -(this.widthSlide * (this.slideItems - 1));
      } else {
        this.positionSlide += this.widthSlide;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  --circle-color: var(--main-color);
}

.home-page {
  display: block;
  position: relative;

  &__content {
    position: relative;
    width: 100%;
    padding: 34px 20px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include mobile {
      align-items: flex-start;
    }

    @include tablet {
      max-width: 60%;
    }

    @include desktop {
      max-width: 50%;
    }
  }

  &__title {
    @include title-uppercase($primary);
    font-size: 30px;

    @include xl-desktop {
      font-size: 34px;
    }
  }
}

.home-main {
  &__header-mb {
    align-self: center;
    margin-bottom: 40px;
    background: no-repeat center url("/home/mobile.svg");
    background-size: cover;
    width: 288px;
    height: 212px;

    @include desktop {
      width: 350px;
      height: 258px;
      margin-bottom: 60px;
    }
  }

  &__header-inner {
    width: 100%;
    height: 100%;
  }

  &__wrapper {
    margin-bottom: 0px;

    @include mobile {
      // max-width: 335px;
      margin: 0 auto;
    }
  }

  &__title {
    @include title-main(21px);
    margin-bottom: 17px;
    width: 100%;

    @include phone {
      font-size: 24px;
    }

    @include tablet {
      font-size: 30px;
    }

    @include desktop {
      font-size: 30px;
    }

    &_part {
      @include title-uppercase($primary, 21px);

      @include phone {
        font-size: 24px;
      }

      @include tablet {
        font-size: 30px;
      }

      @include desktop {
        font-size: 30px;
      }
    }
  }

  &__slogan {
    @include title-rubik;
    color: $primary;
    margin-bottom: 17px;
    font-size: 16px;

    @include desktop {
      font-size: 20px;
    }
  }

  &__subtitles {
    @include text-small;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: 75%;

    @include mobile {
      width: 100%;
    }

    @include desktop {
      width: 100%;
      flex-direction: row;
    }
  }

  &__subtitle {
    margin-bottom: 17px;
    font-size: 14px;
    line-height: 1.4;

    @include desktop {
      width: 45%;
      white-space: break-spaces;
    }
  }
}

.home-blog {
  &__wrapper {
    margin-left: 0px;
    margin-bottom: 24px;
  }

  &__subtitle {
    @include subtitle($primary);
    /*width: 60%;*/
  }

  &__card#blog-card {
    margin-bottom: 40px;
  }

  &__link {
    width: 100%;
  }
}

.home-about {
  position: relative;

  &__dialog-text#dialog-text,
  &__dialog-info#dialog-info {
    margin-bottom: 100px;
  }

  &__dialog-text#dialog-text {
    padding: 21px 42px;
    margin-bottom: 50px;
  }

  &__dialog-info#dialog-info {
    max-width: 253px;
    font-size: 22px;
    padding: 19px 10px 19px 22px;
    border-color: #5426e2;
    color: #5426e2;
    align-self: flex-end;
    line-height: 1.36;
  }

  &__slider_container {
    position: relative;
    border: 2px solid $primary;
    background: #fff;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    padding: 65px 27px;

    @include mobile {
      margin: 0 auto;
      width: 100%;
    }

    @media (max-width: 350px) {
      padding: 65px 17px;
    }
  }
}

.slider {
  &__container {
    overflow: hidden;

    @include mobile {
      width: 330px;
    }

    @media (max-width: 350px) {
      width: 260px;
    }
  }

  &__wrapper {
    display: flex;
    transition: transform 250ms;
  }

  &__item {
    @include mobile {
      flex-basis: 330px;
      flex-shrink: 0;
    }
  }

  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 37px;
    background: #eaeaea;
    border-bottom: 2px solid $primary;
  }

  &__title {
    @include title-uppercase($primary, 20px);
    margin-bottom: 15px;

    @media (max-width: 350px) {
      font-size: 16px;
    }
  }

  &__img {
    min-width: 98px;
    height: 98px;
    border-radius: 50%;
    background-color: #c4c4c4;
    margin-right: 20px;
  }

  &__text-wrapper {
    padding-right: 25px;
    margin-top: 30px;

    @media (max-width: 350px) {
      padding-right: 10px;
      max-width: 260px;
    }
  }

  &__text {
    font-size: 18px;
    margin-bottom: 20px;

    @include mobile {
      font-size: 16px;
    }
  }

  &__arrow {
    position: absolute;
    top: 90%;
    cursor: pointer;

    &_left {
      transform: rotate(180deg);
      left: 5%;
    }

    &_right {
      right: 8%;
    }
  }
}

.home-break {
  &__wrapper {
    @include mobile {
      display: flex;
      padding-left: 15px;
      margin-bottom: 30px;
    }
  }

  &__list {
    @include mobile {
      padding-left: 25px;
      padding-top: 10px;
    }
  }

  &__list-item {
    line-height: 1.4;
    padding-top: 26px;
    padding-left: 25px;
    position: relative;

    background:
      linear-gradient(to right,
        #00A4FF calc(0% + 1px),
        #00A4FF calc(0% + 2px),
        rgba(255, 255, 255, 0) calc(0% + 3px),
        rgba(255, 255, 255, 0) 100%);

    // 21px = psdding-top 26px - width 10px/2
    &::after {
      @include pseudo-el-circle;
      top: calc(0.5rem * 1.4 + 21px);
      left: calc(-1 * (var(--circle) / 2 - 1px));
    }

    // &:first-child {
    //   padding-top: 40px;

    //   &:before {
    //     @include pseudo-el-circle;
    //     top: 0;
    //     left: calc((var(--circle) / 2 * -1 + 1px));
    //   }
    // }

    &:first-child {
      padding-top: 60px;

      &:before {
        @include pseudo-el-circle;
        top: 0;
        left: calc(-1 * (var(--circle) / 2 - 1px));
      }

      // 55px = padding-top 60px - width 10px/2
      &::after {
        @include pseudo-el-circle;
        top: calc(0.5rem * 1.4 + 55px);
        left: calc(-1 * (var(--circle) / 2 - 1px));
      }
    }

    &:last-child {
      // .5rem * 1.4 - половина высоты строки, 26px - padding-top
      background:
        linear-gradient(to top,
          rgb(255, 255, 255) 0%,
          rgb(255, 255, 255) calc(100% - .5rem * 1.4 - 26px),
          rgba(255, 255, 255, 0) calc(100% - .5rem * 1.4 - 25px),
          rgba(255, 255, 255, 0) 100%),
        linear-gradient(to right,
          #00A4FF calc(0% + 1px),
          #00A4FF calc(0% + 2px),
          rgba(255, 255, 255, 0) calc(0% + 3px),
          rgba(255, 255, 255, 0) 100%);
    }

  }
}

.contact {
  display: flex;
  flex-direction: column;

  &__header#contact-header {
    @include mobile {
      width: 157px;
      align-self: flex-end;
      margin-right: 70px;
      margin-bottom: 30px;
    }
  }

  &__info {
    @include text;

    @include mobile {
      padding-left: 15px;
      margin-bottom: 40px;
    }
  }

  &__form#contact-form {
    @include mobile {
      // max-width: 335px;

      .base-btn {
        width: 100%;
        // max-width: 335px;
      }
    }
  }
}

.home-advantages {
  &__title {
    width: 100%;

    @include mobile {
      margin-bottom: 100px;
    }
  }

  &__badge {
    display: flex;
    /*justify-content: space-between;*/
    align-items: center;

    @include mobile {
      margin-bottom: 80px;

      &_one,
      &_three,
      &_five {
        .home-advantages__img {
          margin-right: 30px;
        }
      }

      &_two,
      &_four,
      &_six {
        flex-direction: row-reverse;

        .home-advantages__img {
          margin-left: 30px;
        }
      }
    }

    @include tablet {
      width: 360px;
      justify-content: center;
    }
  }

  &__text {
    color: #000;
    line-height: 1.4;
    margin-bottom: 0;
  }
}

.home-method {
  &__content {
    align-items: center;
  }

  &__title {
    width: 100%;

    @include mobile {
      margin-bottom: 80px;
      width: 95%;
    }

    @include tablet {
      width: 335px;
    }
  }

  &__wrapper {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    max-width: 500px;
    min-width: 330px;

    @media (max-width: 350px) {
      max-width: 90%;
      min-width: 90%;
    }
  }

  &__item {
    line-height: 1.4;
    width: 50%;
    display: flex;
    flex-direction: column;

    &:nth-child(n) {
      .home-method__number {
        position: relative;
      }
    }

    &:first-child,
    &:nth-child(3n+4):not(:last-child) {
      .home-method__number {
        margin-right: 24px;
        background:
          linear-gradient(to right,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 42px,
            rgba(255, 255, 255, 0) 43px,
            rgba(255, 255, 255, 0) 100%),
          linear-gradient(to bottom,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 0) 17px,
            #00A4FF 18px,
            #00A4FF 19px,
            rgba(255, 255, 255, 0) 20px,
            rgba(255, 255, 255, 0) 100%);

        &::before {
          @include pseudo-el-circle;
          top: 13px;
          left: 42px;
        }

        &::after {
          @include pseudo-el-circle;
          top: 13px;
          right: 0px;
        }
      }

      .home-method__text {
        background:
          linear-gradient(to right,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 7px,
            rgba(255, 255, 255, 0) 8px,
            rgba(255, 255, 255, 0) 100%),
          linear-gradient(to top,
            #00A4FF 0%,
            #00A4FF 2px,
            rgba(255, 255, 255, 0) 3px,
            rgba(255, 255, 255, 0) 100%);
      }
    }

    &:nth-child(2),
    &:nth-child(3n+5) {
      .home-method__number {
        background:
          linear-gradient(to right,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 42px,
            rgba(255, 255, 255, 0) 43px,
            rgba(255, 255, 255, 0) 100%),
          linear-gradient(to bottom,
            rgb(255, 255, 255) 0%,
            rgb(255, 255, 255) 17px,
            #00a4ff 18px,
            #00a4ff 19px,
            rgba(255, 255, 255, 0) 20px,
            rgba(255, 255, 255, 0) 100%),
          linear-gradient(to left,
            #00A4FF 0%,
            #00A4FF 2px,
            rgba(255, 255, 255, 0) 3px,
            rgba(255, 255, 255, 0) 100%);

        &::before {
          @include pseudo-el-circle;
          top: 13px;
          left: 42px;
        }
      }

      .home-method__text {
        background:
          linear-gradient(to left,
            #00A4FF 0%,
            #00A4FF 2px,
            rgba(255, 255, 255, 0) 3px,
            rgba(255, 255, 255, 0) 100%),
          linear-gradient(to top,
            #00A4FF 0%,
            #00A4FF 2px,
            rgba(255, 255, 255, 0) 3px,
            rgba(255, 255, 255, 0) 100%);
      }
    }
  }

  &__middle {
    position: relative;
    width: 100%;
    height: 45px;
    background:
      linear-gradient(to top,
        rgb(255, 255, 255) 0%,
        rgb(255, 255, 255) 10px,
        rgba(255, 255, 255, 0) 11px,
        rgba(255, 255, 255, 0) 100%),
      linear-gradient(to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0) 7px,
        #00A4FF 8px,
        #00A4FF 9px,
        rgba(255, 255, 255, 0) 10px,
        rgba(255, 255, 255, 0) 100%);

    &::before {
      @include pseudo-el-circle;
      top: 30px;
      left: 3px;
    }
  }

  &__item>p {
    @media (max-width: 350px) {
      font-size: 12px;
    }
  }

  &__number {
    font-size: 30px;
    color: $primary;
    font-weight: bold;
    margin-bottom: 0;
  }

  &__text {
    line-height: 1.38;
    margin-bottom: 0;
    padding-bottom: 20px;
    padding-right: 20px;
    flex-grow: 2;
  }

  &__text>span {
    font-size: 13px;
    line-height: 1;
  }
}

.home-link {
  @include l-desktop {
    padding-right: 20px;
  }

  &__notebook,
  &__footer-clouds {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__footer-clouds {
    width: 290px;
    top: -125px;

    @include phone {
      width: 400px;
      top: -60px;
    }

    @include tablet {
      width: 530px;
    }

    @include desktop {
      width: 530px;
    }
  }

  &__notebook {
    top: 90px;

    @include phone {
      top: 130px;
      width: 300px;
      height: 216px;
    }

    @include tablet {
      top: 100px;
      width: 390px;
      height: 282px;
    }

    @include desktop {
      top: 100px;
      width: 390px;
      height: 282px;
    }
  }

  &__title {
    @include mobile {
      display: none;
    }
  }

  &__wrapper {
    position: relative;
  }

  &__footer-contact#form-link {
    @media screen and (max-width: $breakpoint-mini-max) {
      margin-top: 300px;
    }

    @include phone {
      margin-top: 550px;
    }
  }

  &__footer-contact {
    &_link {
      color: $primary;
      text-decoration: none;
    }
  }

  &__address {
    margin-top: 80px;

    @include subtitle($primary, 16px, 700);
  }
}

div,
p {
  white-space: break-spaces;
}
</style>
