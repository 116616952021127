<template lang="pug">
#js-scroll.home-container.js-locomotive(data-scroll-container)
  section(data-scroll-section).section
    .home-page.home-main(data-scroll)
      .home-page__container
        .home-page__content
          .home-main__wrapper
            h1.home-main__title
              | Мы&nbsp;&mdash;&nbsp;
              span.home-main__title_part NE
              | KIDAEM
              br
              | Честная разработка
              br
              | сложных веб-сервисов
            h3.home-main__slogan Вдумчиво. Дорого. На совесть.
            .home-main__subtitles
              p.home-main__subtitle Прозрачная методология работы: каждые 2 недели даем значимый для проекта результат
              p.home-main__subtitle Финансовая ответственность за&nbsp;сорванные сроки и предоставление гарантий.
              p.home-main__subtitle Всегда на связи в&nbsp;рабочее время
            NuxtLink(to="project")
              BaseBtn.home-main__order-btn заказать проект
          .home-page__content_img
            div(:style="{position: cloudsVuePosition.position, top: `${cloudsVuePosition.top}px`, left: `${cloudsVuePosition.left}px`, zIndex: `${cloudsVuePosition.zIndex}` }")
              div(data-scroll data-scroll-speed="-3" )
                CloudsVue
            Screen1H560.home-page__screen.home-page__screen_min.home-page__screen_min_s1
            picture
              source(type="image/avif" srcset="~assets/images/home/notebook.avif")
              source(type="image/webp" srcset="~assets/images/home/notebook.webp")
              img(
                src="~assets/images/home/notebook.png"
                decoding="async"
                alt="").home-main__notebook
    .home-page.home-blog(data-scroll)
      .home-page__content
        .home-blog__wrapper
          h2.home-blog__title.home-page__title Блог
          p.home-blog__subtitle
            | Мы рассказываем
            br
            | о своей работе
            br
            | и проектах
        .home-page__content_img
          Screen2H560.home-page__screen.home-page__screen_min
          img.lazy__image.home-blog__tooltip(
            data-src="/home/tooltip.svg" alt="")
          NuxtLink(to="blog").home-blog__link
            BaseBtn.home-blog__btn Перейти на страницу блога
        .home-blog__cards
          MediaCard(v-for="post in pageData.blog_items" :key="post.id" :card="post").home-blog__card#blog-card
    .home-page.home-about(data-scroll)
      .home-page__content.home-about__content
        .home-page__content_img
          Screen3H560.home-page__screen.home-page__screen_min
        .home-about__wrapper
          h2.home-about__title.home-page__title О нас
          DialogCard#dialog-text.home-about__dialog-text Мы вместе с вами заботимся о юридической безопасности проекта - охотно заключаем договор и NDA
          DialogCard#dialog-info.home-about__dialog-info(right :css="dialogCardStylesInfo")
            | 35 000+ часов
            br
            | в веб-разработке
          .home-about__slider_container
            .home-about__sliders
              .slider__header
                .slider__circle-wrapper
                  .slider__circle
                  .slider__circle
                  .slider__circle
              .slider__container
                .slider__wrapper(:style="{transform: `translateX(${positionSlide}px)` }")
                  .home-about__slider.slider__item
                    picture
                      source.lazy__image(
                        type="image/avif"
                        data-srcset="/home/founders.avif")
                      source.lazy__image(
                        type="image/webp"
                        data-srcset="/home/founders.webp")
                      img.lazy__image.slider__img(
                        data-src="/home/founders.png"
                        width="98"
                        height="98"
                        alt="Фото основателей")
                    .slider__text-wrapper
                      h3.slider__title А чем мы подтверждаем свою репутацию?
                      p.slider__text Основатели НеКидаем.рф - самые молодые менторы бизнес-инкубатора и технопарка «Ингрия» в Санкт-Петербурге.
                      p.slider__text Мы помогаем родиться на свет многим интересным проектам.
                      p.slider__text Бизнес-инкубатор «Ингрия» входит в ТОП-10 европейских инкубаторов по версии UBI Global-2015.
                  .home-about__slider.slider__item
                    picture
                      source.lazy__image(
                        type="image/avif"
                        data-srcset="/home/founders2.avif")
                      source.lazy__image(
                        type="image/webp"
                        data-srcset="/home/founders2.webp")
                      img.lazy__image.slider__img(
                        data-src="/home/founders2.png"
                        width="98"
                        height="98"
                        alt="Фото основателей")
                    .slider__text-wrapper
                    .slider__text-wrapper
                      h3.slider__title Мы охотно делимся своими знаниями и опытом
                      p.slider__text Ежегодно мы бесплатно проводим мастер-классы в магистратуре Санкт-Петербургского Государственного Экономического Университета, в Санкт-Петербургской Государственной консерватории имени Н.А. Римского-Корсакова и на других известных площадках Санкт-Петербурга.
                      p.slider__text Проводим личные консультации для стартапов и успешных бизнесов.
                  .home-about__slider.slider__item
                    picture
                      source.lazy__image(
                        type="image/avif"
                        data-srcset="/home/founders3.avif")
                      source.lazy__image(
                        type="image/webp"
                        data-srcset="/home/founders3.webp")
                      img.lazy__image.slider__img(
                        data-src="/home/founders3.png"
                        width="98"
                        height="98"
                        alt="Фото основателей")
                    .slider__text-wrapper
                    .slider__text-wrapper
                      h3.slider__title РЕШЕНИЕ КАКИХ ЗАДАЧ НАМ ПО ПЛЕЧУ?
                      p.slider__text Мы успешно справляемся с проектами уровня Sociate.ru и выше. Sociate.ru – это удобный сервис размещения рекламы в социальных сетях.
                      p.slider__text В разработке Sociate.ru мы принимали самое активное участие: технический директор НеКидаем.рф был одним из ведущих разработчиком сервиса.
            .slider__arrows
              img.lazy__image.slider__arrow.slider__arrow_left(
                data-src="/home/arrow.svg" @click="prevSlide()"
                alt="Переход к предыдущему слайду")
              img.lazy__image.slider__arrow.slider__arrow_right(
                data-src="/home/arrow.svg" @click="nextSlide()"
                alt="Переход к следующему слайду")
    .home-page.home-break(data-scroll)
      .home-page__content
        .home-page__content_img
          Screen4H560.home-page__screen.home-page__screen_min
        .home-break__content-wrapper
          h2.home-page__title.home-break__title Что ломает проекты
          .home-break__container
            .home-break__wrapper
              ul.home-break__list
                li.home-break__list-item
                  | Сорванные сроки
                li.home-break__list-item
                  | Нет понимания, как вести себя с разработчиками,
                  br
                  | которые не возвращают вам деньги и ничего не
                  br
                  | делают по проекту
                li.home-break__list-item
                  | Не понятно, как найти опытного и честного веб-разработчика
                li.home-break__list-item
                  | Отсутствие понятных результатов
                li.home-break__list-item
                  | Отсутствие связи c разработчиками
                li.home-break__list-item
                  | Бесконечные форс-мажоры
                li.home-break__list-item
                  | Исчезнувшие с деньгами разработчики
            .contact
              DialogCard.contact__header#contact-header(:css="dialogCardBreak" right sm) У вас есть своя «боль»?
              .contact__info
                p.contact__info_content
                  | Расскажите нам о ней. Конфиденциально.
                  br
                  | В благодарность мы дадим бесплатную
                  br
                  | часовую консультацию.
                Form(:value="pageData.contact_form").contact__form#contact-form
    .home-page.home-advantages(data-scroll)
      .home-page__content
        .home-page__content_img
          Screen5H560.home-page__screen.home-page__screen_min
        .home-advantages__wrapper
          .home-advantages__content
            h2.home-page__title.home-advantages__title
              | Наши преимущества
            .home-advantages__badge_container
              .home-advantages__badge_wrapper
                .home-advantages__badge.home-advantages__badge_one
                  img.lazy__image.home-advantages__img(:data-src="icons.head" alt="")
                  p.home-advantages__text.home-advantages__text_one
                    | Ваша нервная система
                    br
                    | здорова, ведь проект сдан
                    br
                    | в срок
                .home-advantages__badge.home-advantages__badge_two
                  img.lazy__image.home-advantages__img(:data-src="icons.zoom" alt="")
                  p.home-advantages__text.home-advantages__text_two
                    | Проводим
                    br
                    | всесторонний аудит
                    br
                    | вашего проекта
                .home-advantages__badge.home-advantages__badge_three
                  img.lazy__image.home-advantages__img(:data-src="icons.window" alt="")
                  p.home-advantages__text.home-advantages__text_three
                    | Пишем классный,
                    br
                    | легко читаемый код
              .home-advantages__badge_wrapper
                .home-advantages__badge.home-advantages__badge_four
                  img.lazy__image.home-advantages__img(:data-src="icons.exit" alt="")
                  p.home-advantages__text.home-advantages__text_four
                    | Находим выход
                    br
                    | из (на Ваш взгляд)
                    br
                    | безвыходных ситуаций
                .home-advantages__badge.home-advantages__badge_five
                  img.lazy__image.home-advantages__img(:data-src="icons.calendar" alt="")
                  p.home-advantages__text.home-advantages__text_five
                    | Ежедневно
                    br
                    | отвечаем на вопросы
                    br
                    | о рабочем процессе
                .home-advantages__badge.home-advantages__badge_six
                  img.lazy__image.home-advantages__img(:data-src="icons.phone" alt="")
                  p.home-advantages__text.home-advantages__text_six
                    | Всегда на связи
                    br
                    | в рабочее время
    .home-page.home-method(data-scroll)
      .home-page__content
        .home-page__content_img
          Screen6H560.home-page__screen.home-page__screen_min
        .home-method__content-wrapper
          h2.home-page__title.home-method__title Наша методология
          .home-method__wrapper
            .home-method__content
              .home-method__item.home-method__item_one
                h5.home-method__number 1
                p.home-method__text
                  | Оценка текущего
                  br
                  | положения
                  br
                  | дел в проекте
              .home-method__item.home-method__item_two
                p.home-method__text
                  | Разработка
                  br
                  | стратегии,
                  br
                  | составление
                  br
                  | RoadMap
                h5.home-method__number 2
              .home-method__item.home-method__item_three
                h5.home-method__number 3
                p.home-method__text
                  | Декомпозиция
                  br
                  | по спринтам
              .home-method__item.home-method__item_four
                h5.home-method__number 4
                p.home-method__text
                  | Старт.
                  br
                  | Через две недели
                  br
                  | показываем первые
                  br
                  | значимые результаты
              .home-method__item.home-method__item_five
                p.home-method__text
                  | Тестирование
                  br
                  | Правка багов
                  br
                  | Тестовый релиз
                h5.home-method__number 5
              .home-method__item.home-method__item_six
                h5.home-method__number 6
                p.home-method__text
                  | Обратная связь.
                  br
                  | Учет пожеланий
                  br
                  | клиента. Старт
                  br
                  | второго спринта
              .home-method__item.home-method__item_seven
                h5.home-method__number 7
                p.home-method__text
                  | Старт третьего,
                  br
                  | четвертого, пятого,
                  br
                  | шестого, седьмого...
                  br
                  | спринтов
              .home-method__item.home-method__item_eight
                p.home-method__text
                  | Тестирование
                  br
                  | Правка багов
                  br
                  | Тестовый релиз
                  br
                  span Далее работаем
                    br
                    | итерациями, см. 4-6
                h5.home-method__number 8
              .home-method__item.home-method__item_nine
                h5.home-method__number 9
                p.home-method__text
                  | Финальный релиз.
                  br
                  | Дальнейшее
                  br
                  | сопровождение
                  br
                  | проекта
    .home-page.home-link(data-scroll)
      .home-page__content
        .home-page__content_img
          Screen7H560.home-page__screen.home-page__screen_min
          img.lazy__image.home-link__notebook(
            data-src="/home/notebook_contact.svg"
            alt="")
        .home-link__wrapper
          Form(:value="pageData.contact_form").home-link__footer-contact#form-link
.social-media__wrapper
  SocialLinksView
</template>

<script>
// SVG'S --------
import SvgHead from '~/assets/images/svg/icons/head.svg'
import SvgZoom from '~/assets/images/svg/icons/zoom.svg'
import SvgWindow from '~/assets/images/svg/icons/window.svg'
import SvgExit from '~/assets/images/svg/icons/exit.svg'
import SvgPhone from '~/assets/images/svg/icons/phone.svg'
import SvgCalendar from '~/assets/images/svg/icons/calendar.svg'
// SVG'S ^ ------

export default {
  props: {
    pageData: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        head: SvgHead,
        zoom: SvgZoom,
        window: SvgWindow,
        exit: SvgExit,
        phone: SvgPhone,
        calendar: SvgCalendar,
      },
      cloudsVuePosition: {
        position: 'absolute',
        top: 50,
        left: -150,
        zIndex: 20
      },
      lastScrollY: 0,
      lmS: null,

      positionSlide: 0,
      widthSlide: 538,
      slideItems: 3
    }
  },
  computed: {
    dialogCardStylesInfo() {
      return {
        textTransform: 'uppercase',
        borderColor: '#5426E2',
        color: '#5426E2',
        fontWeight: 'bold',
      }
    },
    dialogCardBreak() {
      return {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        fontSize: '30px',
        padding: '23px 23px 18px',
      }
    },
  },
  mounted() {
    // console.log('notebook')
    // console.log('w', document.documentElement.clientWidth, document.documentElement.clientHeight)
    this.lmS = new this.$locomotiveScroll({
      el: document.querySelector("#js-scroll"),
      smooth: true,
      direction: 'horizontal',
      getDirection: true,
      getSpeed: true,
    })

    const lazyImages = document.querySelectorAll('.lazy__image')
    this.$setLazyLoading(lazyImages, '.full-height')
  },
  beforeUnmount() {
    this.lmS.destroy()
  },
  methods: {
    nextSlide() {
      if (Math.abs(this.positionSlide) / this.widthSlide < this.slideItems - 1) {
        this.positionSlide -= this.widthSlide
      } else {
        this.positionSlide = 0
      }
    },
    prevSlide() {
      if (Math.abs(this.positionSlide) / this.widthSlide === 0) {
        this.positionSlide = -(this.widthSlide * (this.slideItems - 1))
      } else {
        this.positionSlide += this.widthSlide
      }
    },
  }
}
</script>

<style lang="scss" scoped>
._horizontal-scroll {
  height: 100%;
}

.home-container {
  position: relative;
  // display: flex;
  // flex-direction: column;
  height: 100%;

  @include l-desktop {
    flex-direction: row;
  }
}

.social-media {

  &__wrapper {
    width: 100%;
    justify-content: center;
    position: fixed;
    bottom: 12px;
    left: 30px;
    // display: none;
    z-index: 1000;


    @media screen and (min-width: 1024px) and (max-width: 1279px) and (max-height: 800px) {
      display: flex;
      justify-content: flex-start;
      left: 86px;
    }
  }
}

.home-page {
  display: inline-block;
  position: relative;
  vertical-align: top;
  width: 1366px;

  &__content {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: row;

    &_img {
      position: relative;
      margin-top: 40px;
    }

  }

  &__title {

    @include title-uppercase($primary);
    font-size: 30px;

    @include xl-desktop {
      font-size: 34px;
    }
  }

  &__screen {
    display: none;

    &_min {
      display: block;
      position: absolute;
      top: 0;
      left: 0;

      &_s1 {
        left: -300px;
      }

    }
  }
}

.home-main {
  min-width: 1366px;

  @include xl-desktop {
    margin-left: 170px;
  }

  &__header-mb {
    align-self: center;
    margin-bottom: 40px;

    /* @include desktop {
      width: 350px;
      margin-bottom: 60px;
    }*/

  }

  &__notebook {

    position: absolute;
    z-index: 10;
    display: block;
    width: 265px;
    left: 10px;
    top: 149px;
  }

  &__wrapper {
    margin-bottom: 0px;
    margin-top: 70px;
    width: 700px;
  }

  &__title {

    @include title-main(21px);
    margin-bottom: 17px;
    width: 100%;
    font-size: 34px;
    margin-bottom: 35px;


    &_part {
      @include title-uppercase($primary, 21px);
      font-size: 34px;

    }
  }

  &__slogan {
    @include title-rubik;
    color: $primary;

    font-size: 20px;
    margin: 15px 0;
  }

  &__subtitles {

    @include text-small;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    width: 80%;
    margin-bottom: 48px;
  }

  &__subtitle {
    margin-bottom: 17px;

    line-height: 1.4;
    font-size: 12px;

    &:nth-child(1) {
      width: 210px;
    }

    &:nth-child(2) {
      width: 180px;
    }

    &:nth-child(3) {
      width: 140px;
    }
  }
}

.home-blog {
  min-width: 1366px;

  &__tooltip {
    position: absolute;
    display: block;
    top: 200px;
    left: 50px;

  }

  &__title {
    margin-bottom: 100px;
  }

  &__wrapper {
    margin-left: 0px;
    margin-bottom: 24px;
    margin-top: 20px;

  }

  &__subtitle {

    @include subtitle($primary);
    /*width: 60%;*/

    @include l-desktop {
      /*width: 40%;*/
    }
  }

  &__cards {
    margin-top: 20px;
    margin-left: 250px;
    display: grid;
    grid-gap: 10px 40px;
    grid-template-columns: repeat(3, 1fr);

  }

  &__card#blog-card {
    // margin-bottom: 40px;
    width: 15vw;

    @include l-desktop {
      margin-bottom: 0;
    }

    @include xl-desktop {
      width: 12vw;
    }
  }

  &__card :deep(a>img) {
    height: 15vh;
  }

  &__card :deep(a>h4) {
    font-size: 13px;
  }

  &__card :deep(a>p) {
    font-size: 11px;
    margin-bottom: 5px;
    height: 50px;
    overflow: hidden;
  }

  @include l-desktop {
    &__card :deep(a>p) {
      width: 215px;
      height: 35px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow-y: hidden;
      margin-bottom: 0px;
    }
  }

  @include xl-desktop {
    &__card :deep(a>img) {
      height: 13vh;
    }

    &__card :deep(a>h4) {
      font-size: 13px;
    }

    &__card :deep(a>p) {
      font-size: 11px;
      margin-bottom: 5px;
      max-height: 90px;
    }
  }

  &__link {
    width: 100%;
    position: absolute;
  }

  &__btn {
    position: absolute;
    left: -108px;
    top: 353px;

  }
}

.home-about {
  min-width: 1366px;
  position: relative;
  margin-left: -270px;

  &__wrapper {
    margin-top: 20px;
    margin-left: 230px;
  }

  &__title {
    display: block;
    margin-bottom: 100px;
  }

  &__dialog-text#dialog-text,
  &__dialog-info#dialog-info {
    margin-bottom: 100px;

    @include l-desktop {

      z-index: 10;
    }
  }

  &__dialog-text#dialog-text {
    padding: 21px 42px;
    margin-bottom: 50px;

    @include l-desktop {
      padding: 33px;
    }
  }

  &__dialog-info#dialog-info {
    max-width: 300px;
    font-size: 22px;
    padding: 19px 10px 19px 22px;
    border-color: #5426E2;
    color: #5426E2;
    align-self: flex-end;
    line-height: 1.36;
    position: absolute;

    @include l-desktop {
      padding: 25px 16px 23px 24px;
      font-size: 30px;
      max-width: 335px;
      top: 300px;
      left: 450px;
    }
  }

  &__slider_container {
    border: 2px solid $primary;
    background: #fff;
    border-radius: 5px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    overflow: hidden;
    position: absolute;
    flex-direction: row;
    top: 60px;
    left: 678px;
    width: 690px;
    height: 430px;
    padding: 109px 76px 39px;

  }
}

.slider {

  &__container {
    overflow: hidden;
    width: 538px;
  }

  &__wrapper {
    display: flex;
    transition: transform 250ms;
  }

  &__item {
    flex-basis: 538px;
    flex-shrink: 0;
    display: flex;
  }


  &__header {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 37px;
    background: #eaeaea;
    border-bottom: 2px solid $primary;
  }

  &__circle-wrapper {
    display: none;

    @include l-desktop {
      position: absolute;
      display: block;
      top: 0;
      right: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 56px;
      height: 100%;
    }
  }

  &__circle {
    @include simple-circle;
  }

  &__title {

    @include title-uppercase($primary, 20px);
    margin-bottom: 15px;
  }

  &__img {
    min-width: 98px;
    height: 98px;
    border-radius: 50%;
    background-color: #c4c4c4;
    margin-right: 20px;
  }

  &__text-wrapper {
    padding-right: 25px;
    margin-top: 30px;

    @include l-desktop {
      margin-top: 0;
    }
  }

  &__text {
    font-size: 14px;
    margin-bottom: 20px;
  }

  &__arrow {
    position: absolute;
    top: 90%;
    cursor: pointer;

    @include l-desktop {
      top: 52%;
    }

    &_left {
      transform: rotate(180deg);
      left: 5%;

      @include l-desktop {
        left: 16%;
      }
    }

    &_right {
      right: 8%;
    }
  }
}

.home-break {
  min-width: 1280px;

  &__container {
    display: flex;
  }

  &__content-wrapper {
    margin-top: 20px;
    margin-left: 230px;

    @include xl-desktop {
      margin-left: 220px;
    }
  }

  &__wrapper {
    min-width: 590px;

  }

  &__list {
    margin-top: 85px;
    margin-left: 150px;
    font-size: 14px;
  }

  &__list-item {
    line-height: 1.4;
    margin-bottom: 20px;
  }
}

.contact {
  position: relative;


  &__header#contact-header {
    position: absolute;
    top: -30px;
    left: -200px;
    width: 300px;

    @include xl-desktop {
      left: -168px;
      width: 280px;
    }

  }

  &__info {
    width: 340px;
    margin-left: 120px;
    margin-top: -35px;

    @include xl-desktop {
      margin-left: 130px;
    }

    &_content {
      @include text;
    }
  }

  @include l-desktop {
    &__form#contact-form :deep(>.wagtail-form__submitted-title) {
      margin-top: 180px;
    }
  }
}

.home-advantages {
  min-width: 1366px;

  &__wrapper {
    margin-top: 20px;
  }

  &__content {
    @include xl-desktop {
      width: 60vw;
    }
  }

  &__title {
    width: 100%;
    margin-left: 240px;
  }

  &__badge {
    display: flex;
    align-items: center;
    width: 320px;

    &_container {
      display: flex;
      margin-left: 390px;
    }

    &_one {
      margin-bottom: 90px;
    }

    &_two {
      margin-bottom: 60px;
      margin-left: 120px;
    }

    &_three {
      margin-left: 70px;
    }

    &_four {
      margin-bottom: 60px;
      margin-left: 50px;
    }

    &_five {
      margin-bottom: 90px;
      margin-left: 300px;
    }
  }

  &__img {
    width: 74px;
    height: 74px;
    margin-right: 8px;
  }

  &__text {
    color: #000;
    line-height: 1.4;
    font-size: 14px;
    margin-top: 12px;
    align-self: flex-start;

  }
}

.home-method {
  min-width: 1366px;

  &__content {
    align-items: center;

    &-wrapper {
      margin-top: 20px;
    }

    &-wrapper :deep(>h2) {
      @include l-desktop {
        width: 300px;
        margin-left: 100px;
      }
    }

    @include l-desktop {
      width: auto;
      align-items: flex-start;
    }
  }


  &__item {
    line-height: 1.4;
    width: 160px;
    margin-bottom: 45px;
    position: absolute;
    width: fit-content;
    width: -moz-fit-content;

    &_one {
      top: 374px;
      left: 50px;
    }

    &_two {
      top: 250px;
      left: 200px;
    }

    &_three {
      top: 374px;
      left: 350px;

    }

    &_four {
      top: 244px;
      left: 460px;

    }

    &_five {
      top: 140px;
      left: 610px;

    }

    &_six {
      top: 244px;
      left: 760px;

    }

    &_seven {
      top: 134px;
      left: 900px;

    }

    &_eight {
      top: -10px;
      left: 1042px;

    }

    &_nine {
      top: 134px;
      left: 1200px;

    }
  }

  &__number {
    font-size: 30px;
    color: $primary;
    font-weight: bold;
    text-align: center;
    margin-bottom: 0;
    line-height: 1.2;
  }

  margin-bottom: 0;

  &__text {
    line-height: 1.38;
    font-size: 14px;
    margin-bottom: 0;

  }

  &__text>span {
    font-size: 12px;
    line-height: 1;

  }
}

.home-link {
  min-width: 0px;
  width: 1366px;

  @include l-desktop {
    padding-right: 20px;
  }


  &__wrapper {
    margin-left: 800px;
    margin-top: 120px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    min-width: 335px;
    position: relative;
  }

  &__notebook,
  &__footer-clouds {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &__footer-clouds {
    width: 290px;
    top: -125px;

    @include phone {
      width: 400px;
      top: -60px;
    }

    @include tablet {
      width: 530px;
    }

    @include desktop {
      width: 530px;
    }

    @include l-desktop {
      width: auto;
      top: 105px;
      left: 160px;
      transform: none;
    }
  }

  &__notebook {
    top: 7px;
    width: 466px;
    left: 270px;
    transform: none;
  }

  &__footer-contact#form-link {
    margin-top: 0;
    width: 70%;
  }
}

div,
p {
  white-space: break-spaces;
}
</style>
