<template>
    <svg class="home-page__svg" width="1280" height="660" viewBox="1440 0 1280 660" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M1440 5H1557.5" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="1553.5" cy="5" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
        <circle cx="1702.5" cy="5" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
        <path d="M1699 5L1777.5 5L1795.5 28V267.534H1688.5V438" stroke="#00A4FF" stroke-width="2"/>
        <path d="M1795 460H1905" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="1908.5" cy="460" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
        <circle cx="2703.5" cy="460" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
        <path d="M2705 460H2880.5" stroke="#00A4FF" stroke-width="2"/>
    </svg>
</template>
