<template>
  <svg class="home-page__svg" width="1000" height="450" fill="none" xmlns="http://www.w3.org/2000/svg">
    <defs>
      <clouds-python/>
      <clouds-react/>
      <comment1/>
      <comment2/>
      <plus/>
      <symbol id="plus-x">
        <use xlink:href="#plus" width="100%" height="100%" transform="rotate(-45 4 5)"/>
      </symbol>
    </defs>

    <symbol id="screen-1-min" viewBox="0 0 1000 450">
      <circle cx="420" cy="240" r="116" fill="#00A4FF" stroke="#00A4FF"/>
      <circle cx="5" cy="300" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M5 300 h190 l30 -30 h100" fill="none" stroke="#00A4FF" stroke-width="2"></path>
      <circle cx="256" cy="40" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M256 40 h55 l30 30 v150" fill="none" stroke="#00A4FF" stroke-width="2"></path>
      <circle cx="100" cy="445" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M100 445 l50 -55 h175 v-90 h35" stroke="#00A4FF" stroke-width="2" stroke-dasharray="1 7"/>
      <circle cx="150" cy="445" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M150 445 l20 -26 h200 v-80 h35" stroke="#00A4FF" stroke-width="2" stroke-dasharray="1 7"/>

      <circle cx="615" cy="260" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M615 260 v40 h-160" fill="none" stroke="#00A4FF" stroke-width="2"></path>
      <path d="M500 270 h80 V5 h315" fill="none" stroke="#00A4FF" stroke-width="2"></path>
      <circle cx="890" cy="5" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

    </symbol>


    <symbol id="comb-1-min" viewBox="0 0 1000 450">

      <use xlink:href="#screen-1-min" x="0" y="0" width="100%" height="100%"/>
      <use xlink:href="#comment-1" x="266" y="384" width="100%" height="100%"/>
      <use xlink:href="#comment-2" x="466" y="64" width="100%" height="100%"/>
      <use xlink:href="#clouds-react" x="430" y="340" width="100%" height="100%"/>
      <use xlink:href="#clouds-python" x="545" y="170" width="100%" height="100%"/>
      <use xlink:href="#plus" x="150" y="10" width="100%" height="100%"/>
      <use xlink:href="#plus" x="650" y="250" width="100%" height="100%"/>
      <use xlink:href="#plus-x" x="0" y="400" width="11" height="11"/>

      <use xlink:href="#plus-x" x="500" y="140" width="100%" height="100%"/>

    </symbol>

    <use xlink:href="#comb-1-min" x="0" y="0" width="100%" height="100%"/>
  </svg>
</template>
