<template>
    <svg class="home-page__svg" width="1280" height="660" viewBox="5760 0 1280 660" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M6043.5 518.5C6043.5 520.709 6041.71 522.5 6039.5 522.5C6037.29 522.5 6035.5 520.709 6035.5 518.5C6035.5 516.291 6037.29 514.5 6039.5 514.5C6041.71 514.5 6043.5 516.291 6043.5 518.5Z" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <path d="M5721.5 509H5841.22L5858.5 496H5975" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="5975.5" cy="496" r="4" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="5975.5" cy="378" r="4" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <path d="M6058 378C6058 380.209 6056.21 382 6054 382C6051.79 382 6050 380.209 6050 378C6050 375.791 6051.79 374 6054 374C6056.21 374 6058 375.791 6058 378Z" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="6366.5" cy="378" r="4" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="6267.5" cy="590" r="4" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <path d="M6665.5 523C6665.5 525.209 6663.71 527 6661.5 527C6659.29 527 6657.5 525.209 6657.5 523C6657.5 520.791 6659.29 519 6661.5 519C6663.71 519 6665.5 520.791 6665.5 523Z" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="6880.5" cy="442" r="4" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="6442.5" cy="183" r="4" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="6746.5" cy="183" r="4" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="6839.5" cy="183" r="4" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <path d="M6363.5 378H6420.21V183H6442.5" stroke="#00A4FF" stroke-width="2"/>
        <path d="M6741.5 183H6844.5" stroke="#00A4FF" stroke-width="2"/>
        <path d="M6267.5 590.5H6510.5L6527 578H6661.5V523" stroke="#00A4FF" stroke-width="2"/>
        <path d="M5975.5 495.5V314.5L5988 302H6029.5V275.5" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="6029.5" cy="279" r="4" fill="#00A4FF" stroke="#00A4FF" stroke-width="2"/>
        <path d="M5976.5 378H6055.5M5976.5 496.5H6039.5V520" stroke="#00A4FF" stroke-width="2"/>
        <path d="M6878.5 442H7006.5L7017 450.5V499H7211.5" stroke="#00A4FF" stroke-width="2"/>
    </svg>
</template>
