<template>
  <svg style="display: none;">
    <symbol id="comment-2">
      <path
        d="M1.56044 1.85547V0.855469H0.56044L0.560439 1.85547L1.56044 1.85547ZM64.5362 1.85547H65.5362V0.855469H64.5362V1.85547ZM1.56042 46.6654H0.560425H1.56042ZM1.56042 66.0425H0.560425V68.4515L2.26644 66.7507L1.56042 66.0425ZM13.7088 53.9315V52.9315H13.2955L13.0028 53.2234L13.7088 53.9315ZM64.5362 53.9315V54.9315H65.5362V53.9315H64.5362ZM1.56044 2.85547H64.5362V0.855469H1.56044V2.85547ZM2.56042 46.6654L2.56044 1.85547L0.560439 1.85547L0.560425 46.6654H2.56042ZM2.56042 66.0425V46.6654H0.560425V66.0425H2.56042ZM13.0028 53.2234L0.854408 65.3343L2.26644 66.7507L14.4148 54.6397L13.0028 53.2234ZM64.5362 52.9315H13.7088V54.9315H64.5362V52.9315ZM63.5362 1.85547V53.9315H65.5362V1.85547H63.5362Z"
        fill="#00A4FF"/>
      <path fill-rule="evenodd" clip-rule="evenodd"
            d="M19.1573 21.9627L22.2676 22.5621C22.6288 21.8742 23.0578 21.2274 23.5457 20.6306L21.7522 18.0325L22.6632 17.1215L25.272 18.9222C25.7485 18.5409 26.2561 18.1968 26.7903 17.8942L26.063 14.8236L27.2425 14.3056L29.0178 16.9286C29.7462 16.7014 30.5056 16.5447 31.2882 16.4666L31.8572 13.3613H33.1455L33.7168 16.4792C34.2924 16.5427 34.8552 16.6487 35.402 16.7941L37.065 14.099L38.2656 14.5669L37.6687 17.6643C38.3606 18.0185 39.0118 18.4407 39.6136 18.9223L42.2224 17.1215L43.1334 18.0325L41.3399 20.6307C41.7263 21.1034 42.0758 21.6075 42.384 22.1386L45.4669 21.4084L45.9849 22.588L43.3728 24.3559C43.6077 25.0818 43.7723 25.8394 43.8585 26.6206L46.9756 27.1917V28.4802L43.8712 29.0491C43.8088 29.674 43.6964 30.2841 43.5379 30.8754L46.2312 32.5372L45.7789 33.6977L42.6835 33.1011C42.3239 33.807 41.8935 34.4707 41.4017 35.083L43.2007 37.6891L42.3199 38.57L39.7241 36.7781C39.2408 37.1747 38.7246 37.5326 38.1802 37.8471L38.9097 40.9269L37.769 41.4278L36.0027 38.8182C35.2635 39.059 34.4914 39.2268 33.6948 39.3131L33.1242 42.427H31.8785L31.3102 39.3255C30.6368 39.2596 29.9804 39.1355 29.3462 38.9583L27.691 41.6408L26.5307 41.1886L27.1297 38.0805C26.4279 37.7136 25.7687 37.2764 25.1615 36.7781L22.5657 38.57L21.6849 37.6891L23.4839 35.083C23.0925 34.5958 22.7401 34.0761 22.4313 33.5284L19.3636 34.255L18.8627 33.1144L21.483 31.341C21.2501 30.5993 21.0903 29.8252 21.0122 29.0272L17.9099 28.4588V27.2132L21.0246 26.6425C21.0923 26.0185 21.21 25.4094 21.3735 24.8194L18.6894 23.1633L19.1573 21.9627ZM32.4427 36.45C37.1679 36.45 40.9984 32.6196 40.9984 27.8944C40.9984 23.1693 37.1679 19.3388 32.4427 19.3388C27.7176 19.3388 23.8871 23.1693 23.8871 27.8944C23.8871 32.6196 27.7176 36.45 32.4427 36.45Z"
            fill="#00A4FF"/>
    </symbol>
  </svg>
</template>
