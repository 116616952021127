<template>
  <svg class="home-page__svg" width="1366" height="450" viewBox="0 0 1366 450" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <circle cx="5" cy="377" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
    <path d="M5 377 h140 l20 -20 v-336 l16 -16 h25 " stroke="#00A4FF" stroke-width="2"/>
    <circle cx="210" cy="5" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
    <circle cx="370" cy="5" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
    <path d="M370 5 h186 l28 28 v50 l28 28 h900" stroke="#00A4FF" stroke-width="2"/>
  </svg>
</template>
