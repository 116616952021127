<template>
    <svg class="home-page__svg" width="1440" height="743" viewBox="7200 0 1440 743" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7200.5 499H7687.55V355.233H8166.04V230H8640.5" stroke="#00A4FF" stroke-width="2"/>
        <circle cx="7357.5" cy="499" r="5" fill="#00A4FF"/>
        <circle cx="7801.5" cy="355" r="5" fill="#00A4FF"/>
        <circle cx="8236.5" cy="230" r="5" fill="#00A4FF"/>
        <circle cx="7476.5" cy="499" r="5" fill="#00A4FF"/>
        <circle cx="7920.5" cy="355" r="5" fill="#00A4FF"/>
        <circle cx="8355.5" cy="230" r="5" fill="#00A4FF"/>
        <path d="M8319.5 110.5H8141L8120.5 90H7993H7781.5L7761 110.5V258.5H7801.5V351" stroke="#00A4FF" stroke-width="2" stroke-dasharray="2 7"/>
        <circle cx="7595.5" cy="499" r="5" fill="#00A4FF"/>
        <circle cx="8039.5" cy="355" r="5" fill="#00A4FF"/>
        <circle cx="8474.5" cy="230" r="5" fill="#00A4FF"/>
    </svg>
</template>
