<template>
  <svg class="home-page__svg" width="1366" height="450" viewBox="0 0 1366 450" fill="none"
       xmlns="http://www.w3.org/2000/svg">
    <defs>
      <gear/>
    </defs>
    <symbol id="screen-5-h560">
      <path d="M0 410 h80 v-30 h175 l15 -15 h165" stroke="#00A4FF" stroke-width="2"/>
      <circle cx="435" cy="365" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>

      <circle cx="375" cy="365" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <circle cx="430" cy="125" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M375 365 v-200 l15 -15 h40 v-22" stroke="#00A4FF" stroke-width="2"/>

      <circle cx="375" cy="240" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <circle cx="485" cy="240" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M375 240 h110" stroke="#00A4FF" stroke-width="2"/>

      <circle cx="710" cy="380" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <circle cx="800" cy="380" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M710 380 H800" stroke="#00A4FF" stroke-width="2"/>

      <circle cx="750" cy="245" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <circle cx="860" cy="75" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M750 245 h55 v-170 h55" stroke="#00A4FF" stroke-width="2"/>

      <circle cx="1130" cy="75" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <circle cx="1170" cy="165" r="4.5" fill="#00A4FF" stroke="#00A4FF"/>
      <path d="M1130 75 h40 v90 " stroke="#00A4FF" stroke-width="2"/>

    </symbol>

    <symbol id="comb-5-h560" viewBox="0 0 1366 450">

      <use xlink:href="#screen-5-h560" x="0" y="0" width="100%" height="100%"/>
      <use xlink:href="#gear" x="150" y="343" width="100%" height="100%"/>
    </symbol>

    <use xlink:href="#comb-5-h560" x="0" y="0" width="100%" height="100%"/>

  </svg>
</template>
<script>


</script>
